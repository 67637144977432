import React from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { TARGET_URL_QUERY_PARAM } from '../../config/auth.config';
import { APP_ROUTES } from '../../config/routes-config';
import { RouteConfig } from '../../interfaces/routes-config.interface';
import { UserRole } from '../../enums/user-role.enum';
import { Unauthorized } from '../../components/core-ui/unauthorized/unauthorized.component';

/**
 * interface ProtectedRouteProps
 */
interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  isAuthenticated: boolean;
  permittedList?: UserRole[];
  userRole: UserRole;
}
/**
 * functional component ProtectedRoute
 * @param component child component to be rendered in case of authenticated user
 * @param isAuthenticated user authentication status
 */
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  isAuthenticated,
  permittedList,
  userRole,
  ...rest
}) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          permittedList && permittedList.length > 0 ? (
            permittedList.indexOf(userRole) > -1 ? (
              <Component {...props} />
            ) : (
              <Unauthorized />
            )
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH,
              search: `?${TARGET_URL_QUERY_PARAM}=${location.pathname}`
            }}
          />
        )
      }
    />
  );
};
