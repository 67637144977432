import { UnitTypeModel } from '../models/unit-types.model';
import { UnitTypeUiModel } from '../ui-models/unit-type.ui-model';
import { UnitTypeBedsModel } from '../models/unit-type-bdes.model';
import { UnitTypeBedsUiModel } from '../ui-models/unit-type-beds.ui-model';

function mapToUI(unitTypeData: UnitTypeModel): UnitTypeUiModel {
  return {
    id: unitTypeData.unitTypeId || '',
    name: unitTypeData.name,
    coverImage:
      unitTypeData.roomTypePhotos && unitTypeData.roomTypePhotos.length > 0
        ? unitTypeData.roomTypePhotos[0].url
        : '',
    amenities: unitTypeData.amenities,
    maxGuests: unitTypeData.maxGuests,
    roomTypePhotos: unitTypeData.roomTypePhotos,
    roomTypeUnits: unitTypeData.roomTypeUnits,
    availableFrom: unitTypeData.availableFrom,
    ratePlans: unitTypeData.ratePlans,
    fees: unitTypeData.fees,
    shortName: unitTypeData.shortName,
    description: unitTypeData.description,
    currency: unitTypeData.currency ? unitTypeData.currency.toLowerCase() : 'egp',
    houseRules: unitTypeData.houseRules,
    viewLink: unitTypeData.viewLink,
    area: unitTypeData.area,
    bookingMode: unitTypeData.bookingMode,
    isListed: unitTypeData.isListed,
    bathrooms: unitTypeData.bathrooms,
    bedrooms: unitTypeData.bedrooms,
    beds: unitTypeData.beds
      ? unitTypeData.beds.reduce((acc: UnitTypeBedsUiModel[], bedItem: UnitTypeBedsModel) => {
          const updatedAcc = [...acc];
          const foundIndex = acc.findIndex((bedUiModel) => bedUiModel.roomName === bedItem.name);
          if (foundIndex > -1) {
            updatedAcc[foundIndex].bedsCount.push({ count: bedItem.count, id: bedItem.id });
          } else {
            updatedAcc.push({
              roomName: bedItem.name,
              bedsCount: [
                {
                  count: bedItem.count,
                  id: bedItem.id
                }
              ]
            });
          }
          return updatedAcc;
        }, [])
      : undefined,
    unitMeters: unitTypeData.unitMeters,
    cmId: unitTypeData.cmId,
    cmLastModifiedAt: unitTypeData.cmLastModifiedAt,
    cmLastModifiedById: unitTypeData.cmLastModifiedById,
    cmLastModifiedByUsername: unitTypeData.cmLastModifiedByUsername,
    unitTypeLink: unitTypeData.unitTypeLink,
    downPayment: unitTypeData.downPayment,
    insurance: unitTypeData.insurance,
    reservationMode: unitTypeData.reservationMode,
    showRooms: unitTypeData.showRooms,
    unitPrice: unitTypeData.unitPrice,
    units: unitTypeData.units,
    minStayDuration: unitTypeData.minStayDuration,
    maxStayDuration: unitTypeData.maxStayDuration,
    isFeatured: unitTypeData.isFeatured,
    unitTypeGroupId: unitTypeData.unitTypeGroupId,
    propertyName: unitTypeData.propertyName,
    propertyId: unitTypeData.propertyId,
    areaId: unitTypeData.areaId,
    urlLink: unitTypeData.urlLink,
    onboardingMessage: unitTypeData.onboardingMessage,
    expenses: unitTypeData.expenses,
    landlordId: unitTypeData.landlordId || '',
    landlordUsername: unitTypeData.landlordUsername,
    landlordEmail: unitTypeData.landlordEmail,
    landlordPhone: unitTypeData.landlordPhone,
    landlordShare: unitTypeData.landlordShare,
    customPrices: unitTypeData.customPrices || []
  };
}
function mapToModel(unitTypeData: Partial<UnitTypeUiModel>): UnitTypeModel {
  return {
    unitTypeId: unitTypeData.id || undefined,
    name: unitTypeData.name,
    description: unitTypeData.description,
    maxGuests: unitTypeData.maxGuests,
    houseRules: unitTypeData.houseRules,
    viewLink: unitTypeData.viewLink,
    bathrooms: unitTypeData.bathrooms,
    bedrooms: unitTypeData.bedrooms,
    unitMeters: unitTypeData.unitMeters,
    unitTypeLink: unitTypeData.unitTypeLink,
    downPayment: unitTypeData.downPayment,
    isListed: unitTypeData.isListed,
    bookingMode: unitTypeData.bookingMode,
    insurance: unitTypeData.insurance,
    beds: unitTypeData.beds
      ? unitTypeData.beds.reduce((acc: UnitTypeBedsModel[], bedItem: UnitTypeBedsUiModel) => {
          const updatedAcc = [...acc];
          bedItem.bedsCount.forEach((bedCountItem) => {
            updatedAcc.push({
              name: bedItem.roomName,
              id: bedCountItem.id,
              count: bedCountItem.count
            });
          });
          return updatedAcc;
        }, [])
      : undefined,
    reservationMode: unitTypeData.reservationMode,
    unitPrice: unitTypeData.unitPrice,
    minStayDuration: unitTypeData.minStayDuration,
    maxStayDuration: unitTypeData.maxStayDuration,
    ratePlans: unitTypeData.ratePlans,
    roomTypePhotos: unitTypeData.roomTypePhotos,
    fees: unitTypeData.fees,
    isFeatured: unitTypeData.isFeatured,
    units: unitTypeData.units,
    unitTypeGroupId: unitTypeData.unitTypeGroupId,
    urlLink: unitTypeData.urlLink,
    onboardingMessage: unitTypeData.onboardingMessage,
    landlordId: unitTypeData.landlordId,
    landlordUsername: unitTypeData.landlordUsername,
    landlordEmail: unitTypeData.landlordEmail,
    landlordPhone: unitTypeData.landlordPhone,
    landlordShare: unitTypeData.landlordShare,
    payoutsToUpdate: unitTypeData.payoutsToUpdate,
    customPrices: unitTypeData.customPrices,
    amenitiesIds:
      unitTypeData.amenities && unitTypeData.amenities.length > 0
        ? unitTypeData.amenities.map((item) => item.id as string)
        : undefined
  };
}

function mapToUIList(unitTypeList: UnitTypeModel[]): UnitTypeUiModel[] {
  return unitTypeList.map(mapToUI);
}

export default {
  mapToUI,
  mapToModel,
  mapToUIList
};
