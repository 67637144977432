import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './home.module.scss';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import { ReservationsUpdates } from './components/reservations-updates/reservations-updates.component';
import { withAuthorization } from '../../../shared/services/auth/with-authorization/with-authorization.component';
import { UserRole } from '../../../shared/enums/user-role.enum';

import _ from 'lodash';

export type HomeContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component HomeContainer
 * @param {HomeContainerProps} holding question text
 */
export const HomeContainer: React.FC<HomeContainerProps> = ({ isAuthenticated }) => {
  const { t } = useTranslation();

  useEffect(() => {
    trackPage('dashboard');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles['home-container']}>
      <div className={styles['home-container__title']}>{t('DASHBOARD_MODULE.HOME.TITLE')}</div>
      <ReservationsSummary />
    </div>
  );
};

export default connect(mapStateToProps)(HomeContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}

const ReservationsSummary = withAuthorization(
  [UserRole.ADMIN, UserRole.PARTNER_MANAGER],
  true
)(() => <ReservationsUpdates />);
