import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { Button as MIButton } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './reservation-list.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import { InputText } from '../../../shared/components/core-ui/input-text/input-text.component';
import Select, { ValueType } from 'react-select';
import { getAllAreas } from '../../../shared/services/data/lookups.data';
import { AreaUiModel } from '../../../shared/ui-models/area.ui-model';
import { getAllUnitTypes } from '../../../shared/services/data/unit-type.data';
import { UnitTypeUiModel } from '../../../shared/ui-models/unit-type.ui-model';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../shared/components/core-ui/button/button.component';
import { getModuleName } from '../../shared/services/reservation.service';
import { getAllReservations } from '../../shared/services/data.service';
import { ReservationUiModel } from '../../../shared/ui-models/reservation.ui-model';
import { Tabs, TabsPosition } from '../../../shared/components/group-ui/tabs/tabs.component';
import { ReservationStatus } from '../../../shared/enums/reservation-status';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router';
import { APP_ROUTES, PUBLIC_PATH } from '../../../shared/config/routes-config';
import { RouteConfig } from '../../../shared/interfaces/routes-config.interface';
import { CreateReservationModal } from './components/create-reservation/create-reservation-modal.component';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Countdown from 'react-countdown';
import { ReservationChannel } from '../../../shared/enums/reservation-channel.enum';
import { ReservationSource } from '../../../shared/enums/reservation-souce.enum';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
      // backgroundColor: '#ffa358'
    }
  }
});

export type ReservationsListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component ReservationsListContainer
 * @param {ReservationsListContainerProps} holding question text
 */
export const ReservationsListContainer: React.FC<ReservationsListContainerProps> = ({
  isAuthenticated
}) => {
  const isMobileView = useWindowResizer();
  // const { t } = useTranslation();
  const [guestName, setGuestName] = useState('');
  const [area, setArea] = useState('');
  const [reservations, setReservations] = useState<ReservationUiModel[]>([]);
  const [unitType, setUnitType] = useState<string | undefined>();
  const [areaOptions, setAreaOptions] = useState<{ value: string; label: string }[]>([]);
  const [unitTypeOptions, setUnitTypeOptions] = useState<
    { value: string | undefined; label: string }[]
  >([]);
  const [showCreateReseration, setShowCreateReseration] = useState<boolean>(false);
  const [reservationType, setReservationType] = useState<string>();

  const history = useHistory();

  useEffect(() => {
    trackPage('reservations-list');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllAreas(getModuleName()).then((response: AreaUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setAreaOptions(response.map((area) => ({ value: area.name || '', label: area.name })));
      }
    });
    getAllUnitTypes(getModuleName()).then((response: UnitTypeUiModel[] | ErrorInfo) => {
      // setLoadingData(false);
      if (!isErrorInfo(response)) {
        setUnitTypeOptions(
          response.map((unitType) => ({ value: unitType.id, label: unitType.name || '' }))
        );
      }
    });
    getReservations({
      status: ReservationStatus.CHECKED_IN
    });
  }, []);
  const getReservations = (query: object) => {
    getAllReservations(query).then((response: ReservationUiModel[] | ErrorInfo) => {
      // setLoadingData(false);
      if (!isErrorInfo(response)) {
        setReservations(response);
      }
    });
  };

  const counterRenderer = ({
    hours,
    minutes,
    seconds
  }: {
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    // Render a countdown
    return (
      <b className={styles['reservation-list-container__reservation-item__status--highlighted']}>
        {hours}:{minutes}:{seconds}
      </b>
    );
  };

  function Row(props: { reservation: ReservationUiModel }) {
    const { reservation } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow
          className={
            reservation.status === ReservationStatus.CHECKED_IN_STARTED
              ? selectedClasses.root
              : classes.root
          }
        >
          {/* <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell> */}
          <TableCell>
            <div
              className={
                styles['reservation-list-container__reservation-item__reservation-card__actions']
              }
            >
              <MIButton
                aria-controls="property-menu"
                aria-haspopup="true"
                onClick={() => {
                  window.open(
                    PUBLIC_PATH +
                      (
                        APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig
                      ).EDIT.FULL_PATH.replace(':id', reservation.id as string),
                    '_blank'
                  );
                }}
              >
                <EditIcon fontSize={'large'} color="inherit" />
              </MIButton>
            </div>
          </TableCell>
          <TableCell component="th" scope="row">
            {reservation.id}
          </TableCell>
          <TableCell>
            <div
              className={`${styles['reservation-list-container__reservation-item__status']} ${
                styles[
                  `reservation-list-container__reservation-item__status--${reservation.status}`
                ]
              }`}
            >
              {reservation.status ? reservation.status.replace('_', ' ') : ''}
            </div>
          </TableCell>
          <TableCell>{reservation.primaryGuestName}</TableCell>
          <TableCell>{new Date(reservation.checkInDate).toDateString()}</TableCell>
          <TableCell>{new Date(reservation.checkOutDate).toDateString()}</TableCell>
          <TableCell>{reservation.area}</TableCell>
          <TableCell>{reservation.propertyName}</TableCell>
          <TableCell>
            <Tooltip
              title={
                <span
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__item__tooltip'
                    ]
                  }
                >
                  {reservation.unitName}
                </span>
              }
            >
              <div
                className={
                  styles[
                    'reservation-list-container__reservation-item__reservation-card__item__value'
                  ]
                }
              >
                {reservation.unitName}
              </div>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          {/* <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Guests List
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Gender</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reservation.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.customerId}</TableCell>
                        <TableCell align="right">{historyRow.amount}</TableCell>
                        <TableCell align="right">
                          {Math.round(historyRow.amount * row.price * 100) / 100}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell> */}
        </TableRow>
      </>
    );
  }
  return (
    <div className={styles['reservation-list-container']}>
      <div className={styles['reservation-list-container__title']}>
        <span>Reservations</span>
      </div>

      <Tabs
        data={{
          tabsList: [
            { component: <></>, key: 'All' },
            { component: <></>, key: 'Currently Hosting' },
            { component: <></>, key: 'Upcoming' },
            { component: <></>, key: 'Checked Out' }
          ],
          defaultTab: 'Currently Hosting'
        }}
        style={{ position: TabsPosition.LEFT }}
        eventHandlers={{
          tabChangeHandler: (tabKey) => {
            if (tabKey === 'All') {
              getReservations({
                statusQuery: [
                  ReservationStatus.CHECKED_IN,
                  ReservationStatus.CHECKED_OUT,
                  ReservationStatus.IN_HOUSE
                ]
              });
            } else if (tabKey === 'Currently Hosting') {
              getReservations({ status: ReservationStatus.IN_HOUSE });
            } else if (tabKey === 'Upcoming') {
              getReservations({ status: ReservationStatus.CHECKED_IN });
            } else {
              getReservations({ status: ReservationStatus.CHECKED_OUT });
            }
            console.log(tabKey);
          }
        }}
      />
      <div className={styles['reservation-list-container__reservation-item']}>
        <div className={styles['reservation-list-container__reservation-item__form-field']}>
          <div
            className={styles['reservation-list-container__reservation-item__form-field__label']}
          >
            Guest Name
          </div>
          <InputText
            data={{
              value: guestName
            }}
            config={{
              maxRows: 1,
              autoFocus: false
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setGuestName(value);
              }
            }}
          />
        </div>
        <div className={styles['reservation-list-container__reservation-item__form-field']}>
          <div
            className={styles['reservation-list-container__reservation-item__form-field__label']}
          >
            Area
          </div>
          <Select
            options={areaOptions}
            className={styles['reservation-list-container__reservation-item__form-field__select']}
            onChange={(value: ValueType<{ value: string; label: string }, false>) => {
              setArea(value ? value.value : '');
            }}
          />
        </div>
        <div className={styles['reservation-list-container__reservation-item__form-field']}>
          <div
            className={styles['reservation-list-container__reservation-item__form-field__label']}
          >
            Unit Type
          </div>
          <Select
            className={styles['reservation-list-container__reservation-item__form-field__select']}
            options={unitTypeOptions}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setUnitType(value ? value.value : '');
            }}
          />
        </div>
        <div className={styles['reservation-list-container__reservation-item__button-container']}>
          <Button
            onClick={() => {
              const query: any = {};
              if (unitType) {
                query.unitTypeId = unitType;
              }
              if (guestName) {
                query.primaryGuestName = guestName;
              }
              if (area) {
                query.area = area;
              }
              getReservations(query);
            }}
            className={
              styles['reservation-list-container__reservation-item__button-container__btn']
            }
            tracking={{
              action: 'close',
              category: 'unit-types',
              label: 'add-unit-type-modal'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            search
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Id #</TableCell>
              <TableCell>Room</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell>Contact Name</TableCell>
              <TableCell>Check In</TableCell>
              <TableCell>Check Out</TableCell>
              <TableCell>Area</TableCell>
              <TableCell>Property</TableCell>
              <TableCell>Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservations.map((reservation) => (
              <Row key={reservation.id} reservation={reservation} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default connect(mapStateToProps)(ReservationsListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
