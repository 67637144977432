import React, { useState } from 'react';
import { Tab } from './tab.component';
import styles from './tabs.module.scss';

export enum TabsPosition {
  RIGHT = 'right',
  LEFT = 'left'
}

/**
 * interface TabsProps
 */
interface TabsProps {
  data: { tabsList: { key: string; component: JSX.Element; id?: string }[]; defaultTab?: string };
  style: {
    position: TabsPosition;
  };
  eventHandlers?: {
    tabChangeHandler?: (tabKey: string, id?: string) => void;
  };
}

/**
 * functional component Tabs
 * @param data hold key and component for each tab
 */
export const Tabs: React.FC<TabsProps> = ({ data, eventHandlers, style }) => {
  const [activeTab, setActiveTab] = useState<string>(
    data.defaultTab ||
      (data.tabsList && data.tabsList[0] && data.tabsList[0] ? data.tabsList[0].key : '')
  );
  const onClickTabItem = (tab: string) => {
    setActiveTab(tab);
    const findID = data.tabsList.find((tabItem) => tabItem.key === tab);
    if (eventHandlers?.tabChangeHandler) {
      eventHandlers.tabChangeHandler(tab, findID?.id);
    }
  };
  return (
    <div className={styles['tabs']}>
      <ol
        className={
          style.position === TabsPosition.LEFT
            ? styles['tab-list']
            : styles['tab-list--position-right']
        }
      >
        {data.tabsList.map((child, index) => {
          const { key } = child;

          return (
            <Tab
              key={index}
              config={{ activeTab }}
              data={{ key }}
              eventHandlers={{ onClickHandler: onClickTabItem }}
            />
          );
        })}
      </ol>
      <div className={styles['tab-content']}>
        {data.tabsList.map((child) => {
          if (child.key !== activeTab) return undefined;
          return child.component;
        })}
      </div>
    </div>
  );
};
