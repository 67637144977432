/** App config Actions */
export const SET_CURRENT_MODULE_NAME = 'SET_CURRENT_MODULE_NAME';
export const GET_CURRENT_MODULE_NAME = 'GET_CURRENT_MODULE_NAME';

export const SET_HEADER_BACK_LINK = 'SET_HEADER_BACK_LINK';
export const GET_HEADER_BACK_LINK = 'GET_HEADER_BACK_LINK';
export const CLEAR_HEADER_BACK_LINK = 'CLEAR_HEADER_BACK_LINK';

/** User Actions */
export const REGISTER_USER_REQUEST = 'USER_DATA_REQUEST';
export const REGISTER_USER_SUCCESS = 'USER_DATA_SUCCESS';
export const REGISTER_USER_FAILURE = 'USER_DATA_FAILURE';
export const USER_DATA_REQUEST = 'USER_DATA_REQUEST';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';
export const USER_DATA_FAILURE = 'USER_DATA_FAILURE';
export const USER_DATA_CLEANUP = 'USER_DATA_CLEANUP';

export const USER_NOTIFICATION_SUCCESS = 'USER_NOTIFICATION_SUCCESS';
