import React, { useEffect, useState } from 'react';
import styles from './input-text.module.scss';
import { XOR } from 'ts-xor';

export enum InputTextType {
  text = 'text',
  password = 'password',
  email = 'email',
  number = 'number'
}

export enum InputTextErrorMode {
  onFirstBlur = 'blur',
  instant = 'instant'
}
interface ControlledInput {
  value: string | number;
  placeholder?: string;
  errorMessage?: string;
}

interface UncontrolledInput {
  defaultValue: string;
  placeholder?: string;
  errorMessage?: string;
}
/**
 * interface InputTextProps
 */
interface InputTextProps {
  data: XOR<ControlledInput, UncontrolledInput>;
  config: {
    error?: boolean;
    maxRows?: number;
    disabled?: boolean;
    autoFocus?: boolean;
    type?: InputTextType;
    validationRegex?: string;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    showErrorMode?: InputTextErrorMode; // default mode onFirstBlur
  };
  eventHandlers?: {
    onChangeHandler: (value: string, isValid: boolean) => void;
    onEnterPressedHandler?: () => void;
  };
}

/**
 * functional component InputText
 * @param InputTextProps holding data, config and eventHandlers props
 */
export const InputText: React.FC<InputTextProps> = ({ data, config, eventHandlers }) => {
  const [value, setValue] = useState<string | number | undefined>(
    data.value || data.defaultValue || ''
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  const [firstType, setFirstType] = useState<boolean>(true);

  useEffect(() => {
    setValue(data.value);
  }, [data.value]);

  /**
   * function to handle changes taking place from input tag
   * @param event {React.ChangeEvent<HTMLInputElement} change event triggered by input element
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let isValid = true;
    const newValue = event.target.value;
    if (data.defaultValue || data.defaultValue === '') {
      setValue(newValue);
    }
    if (config.required) {
      isValid = newValue !== '';
    }
    if (isValid && config.validationRegex) {
      isValid = new RegExp(config.validationRegex).test(newValue);
    }
    if (isValid && config.minLength) {
      isValid = newValue.length >= config.minLength;
    }
    if (isValid && config.maxLength) {
      isValid = newValue.length <= config.maxLength;
    }
    setIsValid(isValid);
    if (eventHandlers) {
      eventHandlers.onChangeHandler(newValue, isValid);
    }
  };
  return (
    <>
      <input
        disabled={config.disabled}
        className={`${styles['input-text']} ${
          (config.error || !isValid) &&
          (config.showErrorMode === InputTextErrorMode.instant || !firstType)
            ? styles['input-text--error']
            : ''
        }`}
        type={config.type || 'text'}
        autoFocus={config.autoFocus}
        placeholder={data.placeholder || ''}
        value={value}
        onBlur={() => {
          setFirstType(false);
        }}
        onChange={handleInputChange}
        onKeyUp={(e) => {
          if (eventHandlers) {
            if (eventHandlers.onEnterPressedHandler && e.key === 'Enter') {
              eventHandlers.onEnterPressedHandler();
              e.preventDefault();
            }
          }
        }}
      />
      <div className={styles['input-text__error-message-wrapper']}>
        {(config.error || !isValid) &&
          (config.showErrorMode === InputTextErrorMode.instant || !firstType) && (
            <p className={styles['input-text__error-message']}>{data.errorMessage}</p>
          )}
      </div>
    </>
  );
};
