import { createAction } from 'redux-actions';
import * as Actions from '../constants/actions';
import { UserModel } from '../../models/user.model';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import { UserAuthUIModel } from '../../ui-models/user-auth.ui-model';
import { UserNotificationsModel } from '../../models/user-notifications.model';

export const signUpUserRequest = createAction<UserModel>(Actions.REGISTER_USER_REQUEST);
export const signUpUserSuccess = createAction<UserAuthUIModel>(Actions.REGISTER_USER_SUCCESS);
export const signUpUserFailure = createAction<ErrorInfo>(Actions.REGISTER_USER_FAILURE);
export const userDataCleanup = createAction(Actions.USER_DATA_CLEANUP);
export const userDataSuccess = createAction<UserAuthUIModel>(Actions.USER_DATA_SUCCESS);

export const userNotificationSuccess = createAction<UserNotificationsModel>(
  Actions.USER_NOTIFICATION_SUCCESS
);
