import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { logger } from '../middleware';
import rootReducer, { RootState } from '../reducers/index';
import sagas from '../sagas';

function configureStore(initialState?: RootState) {
  const sagaMiddleware = createSagaMiddleware();
  let middleware = applyMiddleware(logger, sagaMiddleware);

  if (process.env.NODE_ENV !== 'production') {
    middleware = composeWithDevTools(middleware);
  }

  const store = createStore(rootReducer, initialState, middleware) as Store<RootState>;

  sagaMiddleware.run(sagas);

  if ((module as any).hot) {
    (module as any).hot.accept(
      () => '../reducers',
      () => {
        const nextReducer = require('../reducers');
        store.replaceReducer(nextReducer);
      }
    );
  }

  return store;
}

const ReduxStore = configureStore();
export default ReduxStore;
