export enum ReservationStatus {
  CONFIRMATION_PENDING = 'confirmation_pending',
  CHECKED_IN = 'checked_in',
  CHECKED_IN_STARTED = 'checkin_started',
  PENDING_CHECK_IN = 'pending_check_in',
  IN_HOUSE = 'in_house',
  CHECKED_OUT = 'checked_out',
  CANCELED = 'canceled',
  EXPIRED = 'expired'
}
