import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { RootState } from '../../redux/reducers';
import { ProtectedRoute } from '../protected-route/protected-route.component';
import { UserRole } from '../../enums/user-role.enum';

/**
 * functional component BlankLayoutComponent
 * @param children to be rendered in component
 */
const BlankLayoutComponent: React.FC = ({ children }) => (
  <>
    {children}
    {/* <main className="body-content">
      <div className={`body-content__container`}>{children}</div>
    </main> */}
  </>
);

/**
 * interface BlankLayoutProps
 */
interface BlankLayoutProps {
  component: React.ComponentType<any>;
  path: string;
  authenticationRequired: boolean;
  isAuthenticated: boolean;
  permittedList?: UserRole[];
  userRole: UserRole;
}

/**
 * functional component BlankLayout
 * @param component to be rendered as component child
 * @param isAuthenticated user authentication status
 * @param authenticationRequired route config whether route will be protected or not
 */
const BlankLayout: React.FC<BlankLayoutProps> = ({
  component: Component,
  isAuthenticated,
  permittedList,
  userRole,
  authenticationRequired,
  ...rest
}) => {
  return authenticationRequired ? (
    <ProtectedRoute
      userRole={userRole}
      isAuthenticated={isAuthenticated}
      permittedList={permittedList}
      component={(matchProps) => (
        <BlankLayoutComponent>
          <Component {...matchProps} />
        </BlankLayoutComponent>
      )}
    />
  ) : (
    <Route
      {...rest}
      render={(matchProps) => (
        <BlankLayoutComponent>
          <Component {...matchProps} />
        </BlankLayoutComponent>
      )}
    />
  );
};

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken,
    userRole: state.auth.role,
    userEmail: state.auth.email
  };
}

export default connect(mapStateToProps)(BlankLayout);
