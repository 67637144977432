import ReactGA from 'react-ga';
import { History, Location } from 'history';
import amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import {
  TRACKING_AMPLITUDE_ID,
  TRACKING_GA_APP_ID,
  FirebaseConversions,
  FacebookConversions,
  TRACKING_FB_ID,
  ConversionEvents
} from '../../../config/tracking.config';

/**
 * function for initialzing tracking for app
 * @param appID
 * @param initialPathname
 */

// let ga4Instance: any;
export const initializeTrackingTools = () => {
  // Google Analytics
  // const ga4react = new React4GA(TRACKING_GA_APP_ID);
  // ga4react.initialize().then(
  //   (ga4) => {
  //     ga4Instance = ga4;
  //     ga4Instance.pageview(window.location.pathname);
  //   },
  //   (err) => {
  //     console.error(err);
  //   }
  // );
  ReactGA.initialize(TRACKING_GA_APP_ID, {
    debug: false,
    alwaysSendToDefaultTracker: true
  });

  // Amplitude
  amplitude.getInstance().init(TRACKING_AMPLITUDE_ID);

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false // enable logs
  };
  ReactPixel.init(TRACKING_FB_ID, undefined, options);
};

/**
 * function for page tracking using interceptor
 * @param {History} history
 * @param {string} pathname
 */
export const routeTrackingInterceptor = (history: History) => {
  history.listen((location: Location) => {
    // ga4Instance.gtag('event', 'pageview', 'path'); // or your custom gtag event
    // if (ga4Instance) {
    //   ga4Instance.pageview(location.pathname);
    // }
    // ReactGA.set({ page: location.pathname });
    // ReactGA.pageview(location.pathname);
    amplitude.getInstance().logEvent('pageview', {
      pageId: location.pathname
    });
  });
};

/**
 * function to set user properties
 * @param userId user universal Id
 * @param email user email
 */
export const setUserProperties = (userId: string, email: string, role: string) => {
  ReactGA.set({
    userId,
    email,
    role
  });
  // if (ga4Instance) {
  //   ga4Instance.gtag('set', 'user_properties', {
  //     User_ID: userId,
  //     userId,
  //     email,
  //     role
  //   });
  // }

  amplitude.setUserId(userId);
  amplitude.getInstance().setUserProperties({
    email,
    role
  });
};

/**
 * function for page tracking
 * @param {string} pageId
 */
export const trackPage = (pageId: string) => {
  ReactGA.pageview(pageId);
  // if (ga4Instance) {
  //   ga4Instance.pageview(pageId);
  // }

  ReactPixel.pageView();
  amplitude.getInstance().logEvent(`pageview: ${pageId}`);
};

/**
 * function for page tracking without changing URL
 * @param {string} pageId
 */
export const trackSubView = (subviewId: string) => {
  ReactGA.modalview(subviewId);
  // if (ga4Instance) {
  //   ga4Instance.event('modalview', subviewId);
  // }
  amplitude.getInstance().logEvent(`modalview: ${subviewId}`);
};

/**
 * functino for Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const trackEvent = (action: string, options?: any) => {
  ReactGA.event({
    action,
    category: (options && options.category) || '',
    label: (options && options.label) || ''
  });

  // if (ga4Instance) {
  //   ga4Instance.event({
  //     action
  //   });
  // }

  ReactPixel.track(action, { ...options });

  amplitude
    .getInstance()
    .logEvent(`${options && options.label ? options.label + '|' : ''}${action}`, {
      ...options
    });
};

export const trackPurchase = (amount: number, currency: string) => {
  ReactGA.event({
    action: FirebaseConversions.purchase,
    category: 'check_in',
    label: 'Confirm Payment'
  });

  ReactPixel.track(FacebookConversions.purchase, { currency, value: amount });

  // if (ga4Instance) {
  //   ga4Instance.event({
  //     action
  //   });
  // }

  amplitude.getInstance().logEvent('purchase', { currency, value: amount });
};

export const trackConversion = (eventType: ConversionEvents, data?: { [key: string]: string }) => {
  if (!!eventType && FirebaseConversions[eventType]) {
    ReactGA.event({
      action: FirebaseConversions[eventType],
      category: (data && data.category) || '',
      label: (data && data.label) || ''
    });
  }
  if (!!eventType && FacebookConversions[eventType]) {
    ReactPixel.track(FirebaseConversions[eventType], data);
  }
};
