import * as React from 'react';
import { isTabletView } from '../../services/helpers/ui-helper/ui-helper.service';

export default function useTabletWindowResizer(): boolean {
  const [isTablet, setIsTablet] = React.useState(isTabletView());

  function handleSizeChange(): void {
    return setIsTablet(isTabletView());
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleSizeChange);

    return () => {
      window.removeEventListener('resize', handleSizeChange);
    };
  }, [isTablet]);

  return isTablet;
}
