import React from 'react';
import { PUBLIC_PATH, HOST_NAME, APP_ROUTES } from '../../../config/routes-config';
import { useTranslation } from 'react-i18next';
import styles from './unauthorized.module.scss';
import { useHistory } from 'react-router';
import { cleanupUserData } from '../../../services/helpers/auth-helper/auth-helper.service';
import Button, { ButtonType, ButtonSize } from '../button/button.component';

interface UnauthorizedProps {
  config?: { hideHomeButton: boolean };
}

/**
 * Unauthorized functional component
 */
export const Unauthorized: React.FC<UnauthorizedProps> = ({ config }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={styles['unauthorized']}>
      <h1 className={styles['unauthorized__title']}>You're not authorized to view this page</h1>
      {!config || (config && !config.hideHomeButton) ? (
        <div className={styles['unauthorized__action-wrapper']}>
          <div className={styles['unauthorized__action-button']}>
            <a href={`${PUBLIC_PATH}`} rel="noopener noreferrer">
              GET BACK TO HOMEPAGE
            </a>
          </div>
          <Button
            onClick={() => {
              cleanupUserData();
              history.push((APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH);
            }}
            tracking={{
              action: 'home',
              category: 'auth',
              label: 'unauthorized'
            }}
            type={ButtonType.primaryInverted}
            size={ButtonSize.large}
          >
            {t('AUTH_MODULE.UNAUTHORIZED_CONTAINER.SWITCH_ACCOUNT')}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
