import { all, takeLatest } from 'redux-saga/effects';
import api from '../../services/utils/api/fixture.api.service';
import * as AuthActions from '../actions/auth.actions';
import { signUpUser } from '../sagas/auth.saga';

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
// const api = debugConfig.useFixtures ? fixtureApiService : API.create()

export default function* rootSaga() {
  yield all([
    // User Saga actions
    takeLatest(AuthActions.signUpUserRequest, signUpUser, api)
    //   sampleWatcher()
  ]);
}
