import * as Actions from '../../constants/actions';
import { handleActions } from 'redux-actions';
import { RootState } from '../index';
import { NavLinkItem } from '../../../interfaces/nav-link.interface';

/**
 * Interface contains app configurations state
 */
export interface AppConfigState {
  currentModuleName: string;
  hideGlobalHeader: boolean;
  headerBackLink: NavLinkItem | null;
}

/**
 * handle the default state
 */
export const initialState = {
  currentModuleName: '',
  hideGlobalHeader: false,
  headerBackLink: null
};
/**
 * App config selectors
 */
export const AppConfigSelectors = {
  currentModuleName: (state: RootState): string => state.appConfig.currentModuleName
};

/**
 * handleAction(type, reducer, defaultState)
 */
export default handleActions<AppConfigState>(
  {
    [Actions.SET_CURRENT_MODULE_NAME]: (state: AppConfigState, action: any) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [Actions.SET_HEADER_BACK_LINK]: (state: AppConfigState, action: any) => {
      return {
        ...state,
        headerBackLink: action.payload
      };
    },
    [Actions.CLEAR_HEADER_BACK_LINK]: (state: AppConfigState, action: any) => {
      return {
        ...state,
        headerBackLink: null
      };
    }
  },
  initialState
);
