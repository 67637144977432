import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import { ResponseModel } from '../../interfaces/response-model.interface';
import AreaMapper from '../../mappers/area.mapper';
import UnitTypeGroupMapper from '../../mappers/unit-type-group.mapper';
import AmenityMapper from '../../mappers/amenity.mapper';
import UserMapper from '../../mappers/user.mapper';
import { AmenityModel } from '../../models/amenity.model';
import { UserModel } from '../../models/user.model';
import { AreaModel } from '../../models/area.model';
import { AmenityUiModel } from '../../ui-models/amenity.ui-model';
import { UserUIModel } from '../../ui-models/user.ui-model';
import { AreaUiModel } from '../../ui-models/area.ui-model';
import api from '../utils/api/api.service';
import errorHelper from '../utils/api/error-helper.service';
import { UnitTypeGroupUiModel } from '../../ui-models/unit-type-group.ui-model';
import { UnitTypeGroupModel } from '../../models/unit-type-group..model';
import { AmenityChannelUiModel } from '../../ui-models/amenity-channel.ui-model';
import AmenityChannelMapper from '../../mappers/amenity-channel.mapper';
import { AmenityChannelModel } from '../../models/amenity-channel.model';

export function getAllAreas(containerName: string): Promise<AreaUiModel[] | ErrorInfo> {
  return api.getAreas().then((response: ApiResponse<ResponseModel<AreaModel[]>, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return AreaMapper.mapToUIList(data.data);
      } else {
        return errorHelper(0, '', containerName, (api.getAreas as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        containerName,
        (api.getAreas as any).displayName
      );
    }
  });
}
export function getAllUnitTypeGroups(
  containerName: string
): Promise<UnitTypeGroupUiModel[] | ErrorInfo> {
  return api
    .getUnitTypeGroups()
    .then((response: ApiResponse<ResponseModel<UnitTypeGroupModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeGroupMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', containerName, (api.getUnitTypeGroups as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (api.getUnitTypeGroups as any).displayName
        );
      }
    });
}

export function getAllAmenities(containerName: string): Promise<AmenityUiModel[] | ErrorInfo> {
  return api
    .getAmenities()
    .then((response: ApiResponse<ResponseModel<AmenityModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return AmenityMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', containerName, (api.getAmenities as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (api.getAmenities as any).displayName
        );
      }
    });
}

export function getAllChannelAmenities(
  containerName: string
): Promise<AmenityChannelUiModel[] | ErrorInfo> {
  return api
    .getChannelAmenities()
    .then((response: ApiResponse<ResponseModel<AmenityChannelModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return AmenityChannelMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', containerName, (api.getChannelAmenities as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (api.getChannelAmenities as any).displayName
        );
      }
    });
}

export function getAllChannelRoomAmenities(
  containerName: string
): Promise<AmenityChannelUiModel[] | ErrorInfo> {
  return api
    .getChannelRoomAmenities()
    .then((response: ApiResponse<ResponseModel<AmenityChannelModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return AmenityChannelMapper.mapToUIList(data.data);
        } else {
          return errorHelper(
            0,
            '',
            containerName,
            (api.getChannelRoomAmenities as any).displayName
          );
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (api.getChannelRoomAmenities as any).displayName
        );
      }
    });
}

export function getAllUsers(containerName: string): Promise<UserUIModel[] | ErrorInfo> {
  return api.getAllUsers().then((response: ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return UserMapper.mapToUIList(data.data);
      } else {
        return errorHelper(0, '', containerName, (api.getAllUsers as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        containerName,
        (api.getAllUsers as any).displayName
      );
    }
  });
}
