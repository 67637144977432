import { combineReducers } from 'redux';
import auth, { AuthStoreState } from './auth/auth.reducer';
import appConfig, { AppConfigState } from './app-config/app-config.reducer';

export interface RootState {
  appConfig: AppConfigState;
  auth: AuthStoreState;
}

export default combineReducers<RootState>({
  appConfig,
  auth
});
