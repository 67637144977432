import * as React from 'react';
import { isMobileView } from '../../services/helpers/ui-helper/ui-helper.service';

export default function useWindowResizer(): boolean {
  const [isMobile, setIsMobile] = React.useState(isMobileView());

  function handleSizeChange(): void {
    return setIsMobile(isMobileView());
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleSizeChange);

    return () => {
      window.removeEventListener('resize', handleSizeChange);
    };
  }, [isMobile]);

  return isMobile;
}
