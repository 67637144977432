import React from 'react';
import styles from './tab.module.scss';

/**
 * interface TabProps
 */
interface TabProps {
  data: {
    key: string;
  };
  config: {
    activeTab: string;
  };
  eventHandlers: {
    onClickHandler: (key: string) => void;
  };
}

/**
 * functional component Tab
 * @param config holds component configuration
 * @param data holds component data
 * @param eventHandlers holds component event handlers
 */
export const Tab: React.FC<TabProps> = ({ config, data, eventHandlers }) => {
  const onClick = () => {
    eventHandlers.onClickHandler(data.key);
  };

  return (
    <li
      className={`${
        config.activeTab === data.key ? styles['tab-list-active'] : styles['tab-list-item']
      }`}
      onClick={onClick}
    >
      {data.key}
    </li>
  );
};
