import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../shared/components/core-ui/button/button.component';
import { connect } from 'react-redux';
import styles from './unauthorized.module.scss';
import { useHistory } from 'react-router';

import { APP_ROUTES, WEBSITE_HOME } from '../../../shared/config/routes-config';
import { RouteConfig } from '../../../shared/interfaces/routes-config.interface';
import { cleanupUserData } from '../../../shared/services/helpers/auth-helper/auth-helper.service';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';

export type UnauthorizedContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component UnauthorizedContainer
 */
export const UnauthorizedContainer: React.FC<UnauthorizedContainerProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    trackPage('login');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles['unauthorized-container']}>
      <div className={styles['unauthorized-container__wrapper']}>
        <h1 className={styles['unauthorized-container__wrapper__title']}>
          {t('AUTH_MODULE.UNAUTHORIZED_CONTAINER.TITLE')}
        </h1>
        <p className={styles['unauthorized-container__wrapper__sub_title']}>
          {t('AUTH_MODULE.UNAUTHORIZED_CONTAINER.DESCRIPTION')}
        </p>
      </div>
      <div className={styles['unauthorized-container__wrapper__submit-button']}>
        <Button
          onClick={() => {
            window.location.href = WEBSITE_HOME;
          }}
          tracking={{
            action: 'switch-account',
            category: 'auth',
            label: 'unauthorized'
          }}
          size={ButtonSize.large}
        >
          {t('AUTH_MODULE.UNAUTHORIZED_CONTAINER.HOME')}
        </Button>
        <Button
          onClick={() => {
            cleanupUserData();
            history.push((APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH);
          }}
          tracking={{
            action: 'home',
            category: 'auth',
            label: 'unauthorized'
          }}
          type={ButtonType.primaryInverted}
          size={ButtonSize.large}
        >
          {t('AUTH_MODULE.UNAUTHORIZED_CONTAINER.SWITCH_ACCOUNT')}
        </Button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(UnauthorizedContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
