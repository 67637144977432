// a library to wrap and simplify api calls
import apisauce, { ApiResponse } from 'apisauce';
import debugConfig from '../../../config/debug.config';
import fixtureApiService from './fixture.api.service';
import { SERVER_BASE_URL } from '../../../config/routes-config';
import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { UserAuthModel } from '../../../models/user-auth.model';
import { getUserAccessToken } from '../../helpers/auth-helper/auth-helper.service';
import { ResponseModel } from '../../../interfaces/response-model.interface';
import { AreaModel } from '../../../models/area.model';
import { PropertyModel } from '../../../models/property.model';
import { Expenses, UnitTypeModel } from '../../../models/unit-types.model';
import { AmenityModel } from '../../../models/amenity.model';
import { UserModel } from '../../../models/user.model';
import { BookingRequestModel } from '../../../models/booking-request.model';
import { ReservationModel, CreateReservationRequestModel } from '../../../models/reservation.model';
import { ReservationDetailsModel } from '../../../models/reservation-details.model';
import {
  CreateBlockDatesModel,
  BlockDatesModel,
  UpdateBlockDatesModel
} from '../../../models/block-dates.model';
import { GuestModel } from '../../../models/guest.model';
import { GuestUiModel } from '../../../ui-models/guest.ui-model';
import { ReservationDetailsUiModel } from '../../../ui-models/reservation.ui-model';
import { PaymentLinkModel, PaymentUIModel } from '../../../ui-models/payment.ui-model';
import { PaymentModel, PaymentSearch } from '../../../models/payment.model';
import {
  BookAvailabilityResponseModel,
  BookOfferResponseModel
} from '../../../ui-models/book.ui-model';
import { UnitTypeGroupModel } from '../../../models/unit-type-group..model';
import { ReservationUpdates } from '../../../ui-models/reservation-updates.ui-model';
import { OccupancyResponseModel } from '../../../ui-models/occupancy.ui-model';
import { UserNotificationsModel } from '../../../models/user-notifications.model';
import { CreateExpenses } from '../../../../UnitTypeModule/containers/edit-unit-type/expenses-list/components/create-reservation/create-expense-modal.component';
import { CreatePayOutDto, Payout, SearchPayOutDto } from '../../../models/payout.model';
import { ReservationActivityModel } from '../../../models/reservation-activity.model';
import { getUserReservations } from '../../../../UserModule/shared/services/data.service';
import { AmenityChannelModel } from '../../../models/amenity-channel.model';
import { ConfigurationModel } from '../../../models/configuration.model';
import { ConfigurationUiModel } from '../../../ui-models/configuration.ui.model';

const create = (baseURL = SERVER_BASE_URL) => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  // tslint:disable-next-line:no-shadowed-variable
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    },
    // here are some default headers
    // 10 second timeout...
    timeout: 240000
  });
  api.addRequestTransform(async (request) => {
    request.httpsAgent = {
      rejectUnauthorized: false
    };
    const jwt = getUserAccessToken();
    if (jwt) request.headers.Authorization = `Bearer ${jwt}`;
  });
  api.addResponseTransform((response) => {
    // if (response.ok) {
    //   if (response.data && response.data.accessToken) {
    //     token.accessToken = response.data.accessToken;
    //   }
    // }
  });

  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  const getRoot = () => api.get('');

  /** User APIs */
  const registerUser = (
    email: string,
    password: string
  ): Promise<ApiResponse<UserAuthModel, ErrorInfo>> =>
    api.post('/auth/register', { email, password });
  const loginUser = (
    email: string,
    password: string
  ): Promise<ApiResponse<UserAuthModel, ErrorInfo>> => api.post('/auth/login', { email, password });
  const changePassword = (
    token: string,
    password: string
  ): Promise<ApiResponse<UserAuthModel, ErrorInfo>> =>
    api.post('/auth/changePassword', { token, password });
  const forgotPassword = (email: string): Promise<ApiResponse<{}, ErrorInfo>> =>
    api.post('/auth/forgotPassword', { email });
  const validateToken = (): Promise<ApiResponse<{}, ErrorInfo>> => api.get('/auth/verifyToken');
  const getUserNotifications = (): Promise<
    ApiResponse<ResponseModel<UserNotificationsModel>, ErrorInfo>
  > => api.get('/notifications/attention');

  const getAreas = (): Promise<ApiResponse<ResponseModel<AreaModel[]>, ErrorInfo>> =>
    api.get('/areas/');
  const createArea = (
    areaData: AreaModel
  ): Promise<ApiResponse<ResponseModel<AreaModel>, ErrorInfo>> => api.post('/areas/', areaData);

  const updateArea = (
    areaId: string,
    areaData: AreaModel
  ): Promise<ApiResponse<ResponseModel<AreaModel>, ErrorInfo>> =>
    api.put('/areas/' + areaId, areaData);

  const deleteArea = (areaId: string): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> =>
    api.delete('/areas/' + areaId);

  const getProperties = (): Promise<ApiResponse<ResponseModel<PropertyModel[]>, ErrorInfo>> =>
    api.post('/properties/search', {});

  const getProperty = (
    propertyId: string
  ): Promise<ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>> =>
    api.get('/properties/' + propertyId);

  const createProperty = (
    propertyData: PropertyModel
  ): Promise<ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>> =>
    api.post('/properties/', propertyData);

  const updateProperty = (
    propertyId: string,
    propertyData: Partial<PropertyModel>
  ): Promise<ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>> =>
    api.put('/properties/' + propertyId, propertyData);

  const deleteProperty = (propertyId: string): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> =>
    api.delete('/properties/' + propertyId);

  const uploadPropertyAsset = (
    formData: FormData,
    propertyId: string
  ): Promise<ApiResponse<{ data: any }, ErrorInfo>> =>
    api.post(`/properties/uploadAsset/${propertyId}`, formData);

  const uploadPropertyCoverImage = (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>> =>
    api.post('/properties/uploadCover', formData);

  const createUnitType = (
    unitTypeData: UnitTypeModel
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> =>
    api.post('/unitTypes/', unitTypeData);

  const getPropertyUnitTypes = (
    propertyId: string
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>> =>
    api.get(`/properties/${propertyId}/unitTypes`);

  const getUnassignedUnitTypes = (): Promise<
    ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>
  > => api.get('/unitTypes/unassigned');

  const getUnitTypes = (): Promise<ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>> =>
    api.get('/unitTypes');

  const searchInUnitTypes = (data: {
    checkInDate?: number;
    checkOutDate?: number;
    landlordId?: string;
  }): Promise<ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>> =>
    api.post('/unitTypes/search', data);

  const getUnitType = (
    unitTypeId: string
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> =>
    api.get(`/unitTypes/${unitTypeId}`);

  const updateUnitType = (
    unitTypeId: string,
    unitTypeData: Partial<UnitTypeModel>
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> =>
    api.put('/unitTypes/' + unitTypeId, unitTypeData);

  const syncUnitTypeChannel = (
    unitTypeId: string
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> =>
    api.post(`/unitTypes/${unitTypeId}/sync`, {});

  const deleteUnitTypeShowroomImage = (data: {
    unitTypeId: number;
    imageId: string;
    roomId: string;
  }): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> =>
    api.post('/unitTypes/deleteShowRoom', data);

  const getAmenities = (): Promise<ApiResponse<ResponseModel<AmenityModel[]>, ErrorInfo>> =>
    api.get('/amenities/');

  const getChannelAmenities = (): Promise<
    ApiResponse<ResponseModel<AmenityChannelModel[]>, ErrorInfo>
  > => api.get('/amenities/channel/list');

  const getChannelRoomAmenities = (): Promise<
    ApiResponse<ResponseModel<AmenityChannelModel[]>, ErrorInfo>
  > => api.get('/amenities/channel/roomList');

  const getAllUsers = (): Promise<ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>> =>
    api.get('/users/');

  const addUser = (
    userModel: UserModel
  ): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> => api.post('/users/', userModel);

  const createAmenity = (
    amenityData: AmenityModel
  ): Promise<ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>> =>
    api.post('/amenities/', amenityData);

  const updateAmenity = (
    amenityId: string,
    amenityData: AmenityModel
  ): Promise<ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>> =>
    api.put('/amenities/' + amenityId, amenityData);

  const deleteAmenity = (amenityId: string): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> =>
    api.delete('/amenities/' + amenityId);

  const uploadAmenityImage = (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>> =>
    api.post('/amenities/uploadImage', formData);
  const uploadDefaultImage = (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<AreaModel>, ErrorInfo>> =>
    api.post(`/areas/uploadDefaultImage`, formData);
  const uploadSponsoredImage = (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<AreaModel>, ErrorInfo>> =>
    api.post(`/areas/uploadSponsoredImage`, formData);
  const uploadUnitTypeShowRoomImage = (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> =>
    api.post('/unitTypes/uploadShowRoomImage', formData);
  const uploadUnitTypeImage = (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> =>
    api.post('/unitTypes/uploadImage', formData);
  const deleteUnitTypeImage = (data: {
    unitTypeId: number;
    imageId: string;
  }): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> =>
    api.post('/unitTypes/deleteImage', data);
  const getBookingRequests = (): Promise<
    ApiResponse<ResponseModel<BookingRequestModel[]>, ErrorInfo>
  > => api.get('/bookings/requests');
  const getBookingRequestsByQuery = (query: {}): Promise<
    ApiResponse<ResponseModel<BookingRequestModel[]>, ErrorInfo>
  > => api.post('/bookings/search', { query });

  const getUserBookings = (
    userId: string
  ): Promise<ApiResponse<ResponseModel<BookingRequestModel[]>, ErrorInfo>> =>
    api.post('bookings/bookingsByUserId', { userId });

  const getBookingRequestDetails = (
    requestId: string
  ): Promise<ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>> =>
    api.get('/bookings/requests/' + requestId);

  const acceptBookingRequest = (
    requestId: string,
    roomId: string
  ): Promise<ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>> =>
    api.post('/bookings/acceptRequest', { requestId, roomId });

  const rejectBookingRequest = (
    requestId: string,
    rejectionReason: string
  ): Promise<ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>> =>
    api.post('/bookings/rejectRequest', { requestId, rejectionReason });

  const getReservations = (query: {}): Promise<
    ApiResponse<ResponseModel<ReservationModel[]>, ErrorInfo>
  > => api.post('/reservations/search', { query });
  const getUserReservations = (
    userId: string
  ): Promise<ApiResponse<ResponseModel<ReservationModel[]>, ErrorInfo>> =>
    api.post('reservations/resevationsByUserId', { userId });

  const getOneReservation = (
    id: string
  ): Promise<ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>> =>
    api.get(`/reservations/${id}`);
  const uploadPaymentReceipt = (
    formData: FormData
  ): Promise<ApiResponse<{ data: any }, ErrorInfo>> =>
    api.post('reservations/uploadReceipt', formData);
  const uploadGuestDocument = (
    formData: FormData
  ): Promise<ApiResponse<{ data: any }, ErrorInfo>> => api.post('guests/uploadDocument', formData);
  const getOneGuest = (id: string): Promise<ApiResponse<ResponseModel<GuestModel>, ErrorInfo>> =>
    api.get(`/guests/${id}`);
  const updateGuest = (
    guest: GuestUiModel
  ): Promise<ApiResponse<ResponseModel<GuestModel>, ErrorInfo>> =>
    api.put(`/guests/${guest.id}`, guest);
  const updateUser = (user: UserModel): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> =>
    api.put(`/users`, user);
  const addGuest = (
    guest: GuestUiModel
  ): Promise<ApiResponse<ResponseModel<GuestModel>, ErrorInfo>> => api.post('/guests', guest);
  const updateOneReservation = (
    reservation: Partial<ReservationDetailsUiModel>
  ): Promise<ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>> =>
    api.put(`/reservations/${reservation.id}`, reservation);
  const postReservationMessage = (
    reservationId: string,
    message: string
  ): Promise<ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>> =>
    api.post(`/reservations/${reservationId}/message`, { message });

  const getReservationActivities = (
    reservationId: string
  ): Promise<ApiResponse<ResponseModel<ReservationActivityModel[]>, ErrorInfo>> =>
    api.get(`/reservations/${reservationId}/activities`);

  const updateOnePayment = (
    payment: PaymentUIModel
  ): Promise<ApiResponse<ResponseModel<PaymentModel>, ErrorInfo>> =>
    api.put(`/payments/${payment.id}`, payment);
  const createOnePayment = (
    payment: PaymentUIModel
  ): Promise<ApiResponse<ResponseModel<PaymentModel>, ErrorInfo>> => api.post('/payments', payment);
  const generatePaymentLink = (
    paymentLink: PaymentLinkModel
  ): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> =>
    api.post('/payments/generatePaymentLink', paymentLink);
  const sendPaymentLink = (
    paymentId: string,
    email: string
  ): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> =>
    api.post('/payments/sendPaymentLink', { paymentId, email });

  const getBookAvailability = (query: { unitTypeId?: string }) =>
    api.get<ResponseModel<BookAvailabilityResponseModel[]>, ErrorInfo>(
      `bookings/availability/unitType/${query.unitTypeId}`
    );
  const getBookOffer = (query: {
    unitTypeId?: string;
    checkInDate: number;
    checkOutDate: number;
  }) =>
    api.post<ResponseModel<BookOfferResponseModel>, ErrorInfo>(
      `bookings/offer/unitType/${query.unitTypeId}`,
      { checkInDate: query.checkInDate, checkOutDate: query.checkOutDate }
    );
  const createReservations = (reservation: CreateReservationRequestModel) =>
    api.post<ResponseModel<ReservationModel>, ErrorInfo>('reservations', reservation);
  const createBlockDates = (blockDates: CreateBlockDatesModel) =>
    api.post<ResponseModel<BlockDatesModel>, ErrorInfo>('blocked-dates', blockDates);
  const updateBlockDates = (id: number, blockDate: UpdateBlockDatesModel) =>
    api.put<ResponseModel<BlockDatesModel>, ErrorInfo>('blocked-dates/' + id, blockDate);
  const getBlockDates = (query: {}) =>
    api.post<ResponseModel<BlockDatesModel[]>, ErrorInfo>('blocked-dates/search', query);
  const reactivateReservation = (reservationId: string, roomId: string) =>
    api.post<ResponseModel<ReservationModel>, ErrorInfo>(
      `reservations/${reservationId}/reActivate`,
      { roomId }
    );

  const getUnitTypeGroups = (): Promise<
    ApiResponse<ResponseModel<UnitTypeGroupModel[]>, ErrorInfo>
  > => api.get('/UnitTypeGroups/');
  const createUnitTypeGroup = (
    areaData: UnitTypeGroupModel
  ): Promise<ApiResponse<ResponseModel<UnitTypeGroupModel>, ErrorInfo>> =>
    api.post('/UnitTypeGroups/', areaData);

  const updateUnitTypeGroup = (
    unitTypeGroupModelId: string,
    unitTypeGroupModelData: UnitTypeGroupModel
  ): Promise<ApiResponse<ResponseModel<UnitTypeGroupModel>, ErrorInfo>> =>
    api.put('/UnitTypeGroups/' + unitTypeGroupModelId, unitTypeGroupModelData);

  const deleteUnitTypeGroup = (
    unitTypeGroupModelId: string
  ): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> =>
    api.delete('/UnitTypeGroups/' + unitTypeGroupModelId);

  const submitBookingMessage = (
    message: string,
    requestId: string
  ): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> =>
    // tslint:disable-next-line: prettier
    api.post(`/bookings/requests/${requestId}/messages`, { message });

  const getMonthlyRevenue = (monthlycount: number) =>
    api.get<ResponseModel<BookAvailabilityResponseModel[]>, ErrorInfo>(
      `report/monthlyRevenue?months=${monthlycount}`
    );
  const getReservationsUpdates = () =>
    api.get<ResponseModel<ReservationUpdates>, ErrorInfo>(`reservations/updates `);
  const getOccupancy = () =>
    api.get<ResponseModel<OccupancyResponseModel[]>, ErrorInfo>(`report/occupancy`);
  const paymentSearch = (query: Partial<PaymentSearch>) =>
    api.post<ResponseModel<PaymentModel[]>, ErrorInfo>(`payments/search`, query);
  const getUserPayments = (userId: string) =>
    api.post<ResponseModel<PaymentModel[]>, ErrorInfo>(`payments/paymentByUserId`, { userId });
  const createExpenses = (expense: CreateExpenses) =>
    api.post<ResponseModel<Expenses>, ErrorInfo>(
      `unitTypes/${expense.unitTypeId}/expense`,
      expense
    );
  const deleteExpenses = (expenseId: string, uniTypeID: string) =>
    api.delete<ResponseModel<Expenses>, ErrorInfo>(`unitTypes/${uniTypeID}/expense/${expenseId}`);

  const createPayout = (payout: FormData) =>
    api.post<ResponseModel<Payout>, ErrorInfo>(`payout`, payout);
  const deletePayout = (payoutId: string) =>
    api.delete<ResponseModel<Payout>, ErrorInfo>(`payout/${payoutId}`);
  const searchPayouts = (payout: SearchPayOutDto) =>
    api.post<ResponseModel<Payout[]>, ErrorInfo>(`payout/search`, payout);

  const getUserByPhoneNumber = (
    phoneNumber: string
  ): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> =>
    api.post('/users/search', { query: { phone: phoneNumber } });

  const getConfiguration = (): Promise<
    ApiResponse<ResponseModel<ConfigurationModel[]>, ErrorInfo>
  > => api.get('/config');

  const updateConfiguration = (
    configuration: ConfigurationUiModel[]
  ): Promise<ApiResponse<ResponseModel<ConfigurationModel[]>, ErrorInfo>> =>
    api.put('/config', { configs: configuration });

  registerUser.displayName = 'registerUser';
  validateToken.displayName = 'validateToken';
  loginUser.displayName = 'loginUser';
  changePassword.displayName = 'changePassword';
  forgotPassword.displayName = 'forgotPassword';
  getUserNotifications.displayName = 'getUserNotifications';
  uploadPropertyAsset.displayName = 'uploadPropertyAsset';
  uploadPropertyCoverImage.displayNAme = 'uploadPropertyCoverImage';
  getAreas.displayName = 'getAreas';
  createArea.displayName = 'postArea';
  updateArea.displayName = 'updateArea';
  deleteArea.displayName = 'deleteArea';
  getProperties.displayName = 'getProperties';
  createProperty.displayName = 'postProperty';
  updateProperty.displayName = 'updateProperty';
  deleteProperty.displayName = 'deleteArea';
  getProperty.displayName = 'getProperty';
  getPropertyUnitTypes.displayName = 'getPropertyUnitTypes';
  getUnassignedUnitTypes.displayName = 'getUnassignedUnitTypes';
  getUnitTypes.displayName = 'getUnitTypes';
  searchInUnitTypes.displayName = 'searchInUnitTypes';
  getUnitType.displayName = 'getUnitType';
  updateUnitType.displayName = 'updateUnitType';
  syncUnitTypeChannel.displayName = 'syncUnitTypeChannel';
  deleteUnitTypeShowroomImage.displayName = 'deleteUnitTypeShowroomImage';
  uploadUnitTypeShowRoomImage.displayNAme = 'uploadUnitTypeShowRoomImage';
  uploadUnitTypeImage.displayName = 'uploadUnitTypeImage';
  deleteUnitTypeImage.displayName = 'deleteUnitTypeImage';
  getAmenities.displayName = 'getAmenities';
  getChannelAmenities.displayName = 'getChannelAmenities';
  getChannelRoomAmenities.displayName = 'getChannelRoomAmenities';
  getAllUsers.displayName = 'getAllUsers';
  addUser.displayName = 'addUser';
  createAmenity.displayName = 'postAmenity';
  createUnitType.displayName = 'displayName';
  updateAmenity.displayName = 'updateAmenity';
  deleteAmenity.displayName = 'deleteAmenity';
  uploadAmenityImage.displayName = 'uploadAmenityImage';
  getBookingRequests.displayName = 'getBookingRequests';
  getBookingRequestsByQuery.displayName = 'getBookingRequestsByQuery';
  getUserBookings.displayName = 'getUserBookings';
  getBookingRequestDetails.displayName = 'getBookingRequestDetails';
  acceptBookingRequest.displayName = 'acceptBookingRequest';
  getReservations.displayName = 'getReservtions';
  getOneReservation.displayName = 'getOneReservation';
  updateOneReservation.displayName = 'updateOneReservation';
  getOneGuest.displayName = 'getOneGuest';
  updateGuest.displayName = 'updateGuest';
  addGuest.displayName = 'addGuest';
  postReservationMessage.displayName = 'postReservationMessage';
  getReservationActivities.displayName = 'getReservationActivities';
  updateOnePayment.displayName = 'updateOnePayment';
  createOnePayment.displayName = 'createOnePayment';
  generatePaymentLink.displayName = 'generatePaymentLink';
  sendPaymentLink.displayName = 'sendPaymentLink';
  uploadPaymentReceipt.displayName = 'uploadPaymentReceipt';
  uploadGuestDocument.displayName = 'uploadGuestDocument';
  getBookAvailability.displayName = 'getBookAvailability';
  getBookOffer.displayName = 'getBookOffer';
  createReservations.displayName = 'createReservations';
  createBlockDates.displayName = 'createBlockDates';
  updateBlockDates.displayName = 'updateBlockDates';
  getBlockDates.displayName = 'getBlockDates';
  updateUser.displayName = 'updateUser';
  getUserReservations.displayName = 'getUserReservations';
  getUserPayments.displayName = 'getUserPayments';
  reactivateReservation.displayName = 'reactivateReservation';
  getUnitTypeGroups.displayName = 'getUnitTypeGroups';
  createUnitTypeGroup.displayName = 'createUnitTypeGroup';
  updateUnitTypeGroup.displayName = 'updateUnitTypeGroup';
  deleteUnitTypeGroup.displayName = 'deleteUnitTypeGroup';
  submitBookingMessage.displayName = 'submitBookingMessage';
  uploadDefaultImage.displayName = 'uploadDefaultImage';
  uploadSponsoredImage.displayName = 'uploadSponsoredImage';
  rejectBookingRequest.displayName = 'rejectBookingRequest';
  getMonthlyRevenue.displayName = 'getMonthlyRevenue';
  getReservationsUpdates.displayName = 'getReservationsUpdates';
  getOccupancy.displayName = 'getOccupancy';
  paymentSearch.displayName = 'paymentSearch';
  createExpenses.displayName = 'createExpenses';
  deleteExpenses.displayName = 'deleteExpenses';
  createPayout.displayName = 'createPayout';
  deletePayout.displayName = 'deletePayout';
  searchPayouts.displayName = 'searchPayouts';
  getUserByPhoneNumber.displayName = 'getUserByPhoneNumber';
  getConfiguration.displayName = 'getConfiguration';
  updateConfiguration.displayName = 'updateConfiguration';
  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  // tslint:disable:object-literal-sort-keys
  return {
    // a list of the API functions from step 2
    // Lookups APIs
    getRoot,
    // User APIs
    registerUser,
    loginUser,
    updateUser,
    changePassword,
    forgotPassword,
    getUserNotifications,
    uploadPropertyAsset,
    uploadPropertyCoverImage,
    getAreas,
    getUnitTypeGroups,
    createArea,
    updateArea,
    deleteArea,
    getProperties,
    createProperty,
    updateProperty,
    deleteProperty,
    getProperty,
    getPropertyUnitTypes,
    getUnassignedUnitTypes,
    createUnitType,
    getUnitTypes,
    searchInUnitTypes,
    getUnitType,
    getUserReservations,
    updateUnitType,
    syncUnitTypeChannel,
    getUserPayments,
    deleteUnitTypeShowroomImage,
    uploadUnitTypeShowRoomImage,
    getAmenities,
    getChannelAmenities,
    getChannelRoomAmenities,
    getAllUsers,
    addUser,
    createAmenity,
    updateAmenity,
    deleteAmenity,
    uploadAmenityImage,
    uploadUnitTypeImage,
    deleteUnitTypeImage,
    getBookingRequests,
    getBookingRequestsByQuery,
    getUserBookings,
    getBookingRequestDetails,
    acceptBookingRequest,
    validateToken,
    getReservations,
    getOneReservation,
    updateOneReservation,
    getOneGuest,
    updateGuest,
    addGuest,
    postReservationMessage,
    getReservationActivities,
    updateOnePayment,
    createOnePayment,
    generatePaymentLink,
    sendPaymentLink,
    uploadPaymentReceipt,
    uploadGuestDocument,
    getBookAvailability,
    getBookOffer,
    createReservations,
    createBlockDates,
    updateBlockDates,
    getBlockDates,
    reactivateReservation,
    createUnitTypeGroup,
    updateUnitTypeGroup,
    deleteUnitTypeGroup,
    submitBookingMessage,
    uploadSponsoredImage,
    uploadDefaultImage,
    rejectBookingRequest,
    getMonthlyRevenue,
    getReservationsUpdates,
    getOccupancy,
    paymentSearch,
    createExpenses,
    deleteExpenses,
    createPayout,
    deletePayout,
    searchPayouts,
    getUserByPhoneNumber,
    getConfiguration,
    updateConfiguration
  };
  // tslint:enable:object-literal-sort-keys
};

const api = debugConfig.useFixtures ? fixtureApiService : create();

// let's return back our create method as the default.
export default api;
export type ApiType = typeof api;
