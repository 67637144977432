// export default function loggerMiddleware(store) {
//   return next => action => {
//     console.log(action);
//     return next(action);
//   };
// }

import { createLogger } from 'redux-logger';
import { ActionType } from 'typesafe-actions';

export type LoggerAction = ActionType<any>;

const actionTransformer = (action: LoggerAction) => {
  if (action.type === 'BATCHING_REDUCER.BATCH') {
    action.payload.type = action.payload.map((next: LoggerAction) => next.type).join(' => ');
    return action.payload;
  }

  return action;
};

const level = 'info';

// const logger = {};

// for (const method in console) {
//   // eslint-disable-next-line
//   if (typeof console[method] === 'function') {
//     // eslint-disable-next-line
//     logger[method] = console[method].bind(console);
//   }
// }
// // eslint-disable-next-line
// logger[level] = function levelFn(...args) {
//   const lastArg = args.pop();

//   if (Array.isArray(lastArg)) {
//     return lastArg.forEach(item => {
//       // eslint-disable-next-line
//       console[level].apply(console, [...args, item]);
//     });
//   }

//   // eslint-disable-next-line
//   console[level].apply(console, arguments);
// };

export default createLogger({
  actionTransformer,
  level
  // logger
});
