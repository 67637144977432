/* eslint-disable indent */
export const ERRORS = {
  DEFAULT: {
    DEFAULT: {
      field: '',
      errorMessage: 'Something went wrong. Please contact support.'
    },
    // 400s
    400: {
      field: '',
      errorMessage: 'Request is not valid. Please contact support.'
    },
    401: {
      field: '',
      errorMessage: 'Unauthorized access. Please contact support.'
    },
    404: {
      field: '',
      errorMessage: "Can't find requested resource. Please contact support."
    },

    // 500s
    500: {
      field: '',
      errorMessage: 'Internal server error. Please try again later'
    },
    501: {
      field: '',
      errorMessage: 'Service unavailable. Please try again later'
    }
  },
  HOME: {},
  AUTH_MODULE: {
    DEFAULT: {
      400: {
        'MS-4001': {
          field: '',
          errorMessage: 'User is not valid. please contact support.'
        }
      },
      500: {
        'MS-5000': {
          field: '',
          errorMessage: 'SMS Gateway error. Please try again later'
        }
      }
    },
    registerUser: {
      409: {
        'Invalid Request': {
          errorMessage:
            // eslint-disable-next-line quotes
            "Email already registered, if you can't remember you password, please email us on support@birdnestlife.com",
          type: 'warning',
          field: 'email'
        }
      }
    },
    signUpUser: {
      409: {
        'Invalid Request': {
          errorMessage:
            // eslint-disable-next-line quotes
            "Email already registered, if you can't remember you password, please email us on support@birdnestlife.com",
          type: 'warning',
          field: 'email'
        }
      }
    },
    loginUser: {
      409: {
        'Invalid Request': {
          errorMessage: 'Incorrect email or password, please try again.',
          type: 'warning',
          field: 'email'
        }
      },
      400: {
        'Invalid Request': {
          errorMessage: 'Incorrect email or password, please try again.',
          type: 'warning',
          field: 'email'
        }
      }
    }
  },
  SURVEY_SLIDER: {
    DEFAULT: {
      500: {
        INVALID_ID: {
          field: '',
          errorMessage: 'Survey is invalid'
        }
      },
      404: {
        INVALID_ID: {
          field: '',
          errorMessage: 'Survey not found'
        }
      }
    },
    getSurveyById: {
      404: {
        INVALID_ID: {
          field: '',
          errorMessage: 'Survey not found'
        }
      }
    }
  },
  SURVEY: {
    fetchSurveyData: {
      401: {
        'Invalid Request': {
          errorMessage:
            'We are sorry, but you have already taken survey before.\n\nYou can explore more suveys, by clicking below',
          type: 'warning'
        }
      },
      404: {
        'Not Found': {
          errorMessage:
            'This survey is invalid or expired!\n\nYou can explore more suveys, by clicking below',
          type: 'danger'
        }
      }
    },
    fetchSurveyByCampaignId: {
      401: {
        'Invalid Request': {
          errorMessage:
            'We are sorry, but you have already taken survey before.\n\nYou can explore more suveys, by clicking below',
          type: 'warning'
        }
      },
      404: {
        'Not Found': {
          errorMessage:
            'This survey is invalid or expired!\n\nYou can explore more suveys, by clicking below',
          type: 'danger'
        }
      }
    }
  },
  BUILDER_MODULE: {
    signUpUser: {
      401: {
        'Invalid Request': {
          errorMessage:
            // eslint-disable-next-line quotes
            "Email already registered, if you can't remember you password, please email us on support@birdnestlife.com",
          type: 'warning',
          field: 'email'
        }
      }
    }
  },
  CAMPAIGN_MODULE: {
    getCampaignById: {
      403: {
        Unauthorized: {
          // eslint-disable-next-line quotes
          errorMessage: "It seems like you don't have access to this survey.",
          type: 'danger'
        }
      },
      404: {
        'Not Found': {
          errorMessage: 'It seems like you tried to access a survey that does not exist.',
          type: 'danger'
        }
      }
    }
  }
};
