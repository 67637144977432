import { call, put, select } from 'redux-saga/effects';
import { ApiType } from '../../services/utils/api/api.service';
import { signUpUserRequest } from '../actions/auth.actions';
import * as AuthActions from '../actions/auth.actions';
import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import { UserAuthModel } from '../../models/user-auth.model';
import errorHelper from '../../services/utils/api/error-helper.service';
import { AppConfigSelectors } from '../reducers/app-config/app-config.reducer';
import userAuthMapper from '../../mappers/user-auth.mapper';

export function* signUpUser(api: ApiType, action: ReturnType<typeof signUpUserRequest>) {
  const { email, password } = action.payload;
  const response: ApiResponse<UserAuthModel, ErrorInfo> = yield call(
    api.registerUser,
    email,
    password || ''
  );
  if (response.ok) {
    const { data } = response;
    if (data) {
      const mappedData = userAuthMapper.mapToUI(data);
      yield put(AuthActions.signUpUserSuccess(mappedData));
    } else {
      const container = yield select(AppConfigSelectors.currentModuleName);
      const error: ErrorInfo = errorHelper(0, '', container, (api.registerUser as any).displayName);
      yield put(AuthActions.signUpUserFailure(error));
    }
  } else {
    const container = yield select(AppConfigSelectors.currentModuleName);
    const error: ErrorInfo = errorHelper(
      Number(response.status),
      response.data && response.data.errorCode
        ? String(response.data.errorCode || '')
        : response.problem,
      container,
      (api.registerUser as any).displayName
    );

    yield put(AuthActions.signUpUserFailure(error));
  }
}
