import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import { connect } from 'react-redux';
import styles from './forgot-password.module.scss';
import { store } from 'react-notifications-component';
import { useHistory } from 'react-router';
import {
  InputText,
  InputTextType
} from '../../../shared/components/core-ui/input-text/input-text.component';
import { VALIDATION_REGEX } from '../../../shared/components/validation.config';
import { FormElementData } from '../../../shared/interfaces/form-element-data.interface';
import { APP_ROUTES, PUBLIC_PATH } from '../../../shared/config/routes-config';
import { forgotUserPassword } from '../../../shared/services/data/auth.data';
import { getModuleName } from '../../shared/services/auth.service';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackEvent, trackPage } from '../../../shared/services/utils/tracking/tracking.service';

export type ForgotPasswordContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component ForgotPasswordContainer
 * @param {ForgotPasswordContainerProps} holding question text
 */
export const ForgotPasswordContainer: React.FC<ForgotPasswordContainerProps> = ({
  isAuthenticated
}) => {
  const [emailField, setEmailField] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    trackPage('forgot-password');
    if (isAuthenticated) {
      trackEvent('authenticated-auto-redirect', {
        category: 'auth',
        label: 'forgot-password'
      });
      history.push(APP_ROUTES.HOME_MODULE.FULL_PATH);
    }
    return () => {
      store.removeNotification('forgot-password-failed');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * function to handle sign up submission
   */
  const submitHandler = () => {
    if (emailField.value && emailField.isValid) {
      setIsLoading(true);
      store.removeNotification('forgot-password-failed');
      forgotUserPassword(emailField.value as string, getModuleName()).then(
        (response: {} | ErrorInfo) => {
          if (isErrorInfo(response)) {
            setTimeout(() => {
              setIsLoading(false);
              if (response.status === 409) {
                store.addNotification({
                  id: 'forgot-password-failed',
                  title: t(
                    'AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.NOTIFICATIONS.EMAIL_NOT_EXISTS.TITLE'
                  ),
                  message: t(
                    'AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.NOTIFICATIONS.EMAIL_NOT_EXISTS.MESSAGE'
                  ),
                  type: 'danger',
                  insert: 'top',
                  container: 'top-center',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut']
                });
                trackEvent('failed-email-not-exist', {
                  category: 'auth',
                  label: 'forgot-password'
                });
              } else {
                store.addNotification({
                  id: 'forgot-password-failed',
                  title: t(
                    'AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.NOTIFICATIONS.FORGOT_PASSWORD_FAILED.TITLE'
                  ),
                  message: t(
                    'AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.NOTIFICATIONS.FORGOT_PASSWORD_FAILED.MESSAGE'
                  ),
                  type: 'danger',
                  insert: 'top',
                  container: 'top-center',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut']
                });
                trackEvent('failed', {
                  category: 'auth',
                  label: 'forgot-password'
                });
              }
            }, 500);
          } else {
            trackEvent('success', {
              category: 'auth',
              label: 'forgot-password'
            });
            setIsLoading(false);
            setShowSuccessMessage(true);
          }
        }
      );
    }
  };

  return (
    <div className={styles['forgot-password-container']}>
      {showSuccessMessage ? (
        <div className={styles['forgot-password-container__illustration']}>
          <img
            src={`${PUBLIC_PATH}/assets/jpeg/email-sent.jpg`}
            alt="success"
            className={styles['forgot-password-container__illustration__image']}
          />
          <div className={styles['forgot-password-container__illustration__message']}>
            {t('AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.SUCCESS_MESSAGE')}
          </div>
        </div>
      ) : (
        <div className={styles['forgot-password-container__wrapper']}>
          <h1 className={styles['forgot-password-container__wrapper__title']}>
            {t('AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.TITLE')}
          </h1>
          <p className={styles['forgot-password-container__wrapper__sub_title']}>
            {t('AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.SUB_TITLE')}
          </p>
          {/* <p className={styles['forgot-password-container__wrapper__label']}>
            {t('AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.EMAIL_LABEL')}
          </p> */}
          <InputText
            config={{
              autoFocus: true,
              type: InputTextType.email,
              required: true,
              validationRegex: VALIDATION_REGEX.EMAIL
            }}
            data={{
              placeholder: t('AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.EMAIL_PLACEHOLDER'),
              errorMessage: t('AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.EMAIL_ERROR_MESSAGE'),
              defaultValue: ''
            }}
            eventHandlers={{
              onChangeHandler: (value, isValid) => {
                setEmailField({ value, isValid });
              },
              onEnterPressedHandler: submitHandler
            }}
          />

          <div className={styles['forgot-password-container__wrapper__submit-button']}>
            <Button
              onClick={submitHandler}
              tracking={{
                action: 'submit',
                category: 'auth',
                label: 'forgot-password'
              }}
              size={ButtonSize.full}
              disabled={!emailField.isValid}
              loading={isLoading}
            >
              {t('AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.SUBMIT_BUTTON')}
            </Button>
          </div>
          {/* <div className={styles['forgot-password-container__wrapper__registration']}>
            <div className={styles['forgot-password-container__wrapper__registration__message']}>
              {t('AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.LOGIN_MESSAGE')}
            </div>
            <div
              className={styles['forgot-password-container__wrapper__registration__link']}
              onClick={() => {
                history.push({
                  pathname: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH
                });
              }}
            >
              {t('AUTH_MODULE.FORGOT_PASSWORD_CONTAINER.LOGIN_LINK')}
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ForgotPasswordContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
