import debugConfig from './debug.config';

// tslint:disable: no-console
if (debugConfig.showTrackingLogs) {
  console.group();
  console.info('*** GA Tracking ID *** ', process.env.REACT_APP_GA_ID);
  console.info('*** LogRocket Tracking ID *** ', process.env.REACT_APP_LOG_ROCKET_ID);
  console.info('*** Amplitude Tracking ID *** ', process.env.REACT_APP_AMPLITUDE_ID);
  console.groupEnd();
}

export const TRACKING_GA_APP_ID = process.env.REACT_APP_GA_ID || 'UA-194230032-3';
export const TRACKING_AMPLITUDE_ID =
  process.env.REACT_APP_AMPLITUDE_ID || 'ecd37fbdca43eda5d8344f116bba1868';
export const TRACKING_FB_ID = process.env.REACT_APP_FACEBOOK_ID || '3354236324849319';

export enum ConversionEvents {
  addPaymentInfo = 'addPaymentInfo',
  search = 'search',
  addToCart = 'addToCart',
  completeRegister = 'completeRegister',
  purchase = 'purchase',
  customizeProduct = 'customizeProduct',
  lead = 'lead',
  initiateCheckout = 'initiateCheckout'
}

export enum FirebaseConversions {
  addPaymentInfo = 'add_payment_info',
  addToCart = 'add_to_cart',
  completeRegister = 'complete_registration',
  customizeProduct = 'select_item',
  lead = 'lead',
  search = 'search',
  purchase = 'purchase',
  initiateCheckout = 'checkout'
}

export enum FacebookConversions {
  addPaymentInfo = 'AddPaymentInfo',
  addToCart = 'AddToCart',
  completeRegister = 'CompleteRegistration',
  customizeProduct = 'CustomizeProduct',
  purchase = 'Purchase',
  lead = 'Lead',
  search = 'Search',
  initiateCheckout = 'InitiateCheckout'
}

export const SURVEY_MODULE_TRACKING = {
  SUB_VIEWS: {
    SURVEY_NOT_FOUND: '/survey/not-found',
    SURVEY_TAKEN: '/survey/survey-taken',
    SURVEY_SUCCESS: '/survey/success'
  },
  EVENTS: {
    START_SUCCESS_SURVEY: 'explore-button-survey-start',
    EXPLORE_NOT_FOUND_SURVEY: 'explore-button-survey-not-found',
    EXPLORE_TAKEN_SURVEY: 'explore-button-survey-taken'
  }
};
