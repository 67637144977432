import Cookies from 'js-cookie';
import {
  userDataCleanup,
  userDataSuccess,
  userNotificationSuccess
} from '../../../redux/actions/auth.actions';
import { USER_ACCESS_TOKEN, USER_ID_COOKIE_NAME } from '../../../config/cookies.config';
import ReduxStore from '../../../redux/store';
import { UserAuthUIModel } from '../../../ui-models/user-auth.ui-model';
import jwt_decode from 'jwt-decode';
import { UserNotificationsModel } from '../../../models/user-notifications.model';
import { UserAuthModel } from '../../../models/user-auth.model';
import { UserRole } from '../../../enums/user-role.enum';

/**
 * function to get user token from data store
 * @returns {string | undefined}
 */
export function getUserAccessToken(): string | undefined {
  return ReduxStore.getState().auth.accessToken;
  // if(!token) {
  //   const tempToken = Cookies.get(USER_ACCESS_TOKEN);
  //   if(tempToken){
  //     token = tempToken;
  //     ReduxStore.dispatch(registerUserSuccess({
  //       accessToken: token,
  //       email:
  //     }));
  //   }
  // }
}

export function refreshUserToken(): UserAuthUIModel | null {
  const accessToken = Cookies.get(USER_ACCESS_TOKEN);
  if (accessToken && accessToken !== 'undefined') {
    const { email, userId, role, permissions } = jwt_decode<UserAuthUIModel>(accessToken);
    const userData: UserAuthUIModel = {
      accessToken,
      email,
      userId,
      tokenExpiresIn: 365,
      role,
      permissions
    };
    // ReduxStore.dispatch(registerUserSuccess(userData));
    ReduxStore.dispatch(
      userDataSuccess({
        accessToken: userData.accessToken,
        tokenExpiresIn: userData.tokenExpiresIn,
        userId: userData.userId,
        email: userData.email,
        role: userData.role,
        permissions: userData.permissions
      })
    );
    return userData;
  } else {
    return null;
  }
}

/**
 * function to set user token to cookies and data store
 * @param userData
 */
export function setUserToken(userData: UserAuthUIModel): void {
  Cookies.set(USER_ACCESS_TOKEN, userData.accessToken, { expires: userData.tokenExpiresIn });
  Cookies.set(USER_ID_COOKIE_NAME, userData.userId, { expires: userData.tokenExpiresIn });
  // ReduxStore.dispatch(registerUserSuccess(userData));
  ReduxStore.dispatch(
    userDataSuccess({
      accessToken: userData.accessToken,
      tokenExpiresIn: userData.tokenExpiresIn,
      userId: userData.userId,
      email: userData.email,
      role: userData.role
    })
  );
}

export function hasAccess(allowedPermissions: string[]): boolean {
  const accessToken = Cookies.get(USER_ACCESS_TOKEN);
  if (accessToken && accessToken !== 'undefined') {
    const { permissions } = jwt_decode<UserAuthUIModel>(accessToken);
    if (permissions) {
      return allowedPermissions.some((item) => permissions.includes(item));
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function hasRole(allowedRoles: UserRole[]): boolean {
  const accessToken = Cookies.get(USER_ACCESS_TOKEN);
  if (accessToken && accessToken !== 'undefined') {
    const { role } = jwt_decode<UserAuthUIModel>(accessToken);
    if (role) {
      return allowedRoles.indexOf(role) > -1;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * function to clean up user data from cookies and data store
 */
export function cleanupUserData() {
  ReduxStore.dispatch(userDataCleanup());
  Cookies.remove(USER_ACCESS_TOKEN);
  Cookies.remove(USER_ID_COOKIE_NAME);
}

export function setUserNotification(userNotifications: UserNotificationsModel): void {
  // ReduxStore.dispatch(registerUserSuccess(userData));
  ReduxStore.dispatch(userNotificationSuccess(userNotifications));
}
