import { RouteConfig } from '../interfaces/routes-config.interface';
import { UserRole } from '../enums/user-role.enum';
// tslint:disable: no-console
console.group();
console.info('*** PUBLIC PATH *** ', process.env.PUBLIC_URL);
console.info('*** BE ROUTE ***', process.env.REACT_APP_BE_API_ENDPOINT);
console.groupEnd();
// tslint:enable: no-console
export const PUBLIC_PATH = process.env.PUBLIC_URL || '/';
export const SERVER_BASE_URL = process.env.REACT_APP_BE_API_ENDPOINT;
export const HOST_NAME = '//' + window.location.host;
export const WEBSITE_HOME = process.env.REACT_APP_WEBSITE_BASE || 'https://birdnestlife.com';

export const APP_ROUTES: RouteConfig = {
  HOME_MODULE: {
    PATH: '/home',
    FULL_PATH: '/home',
    DISPLAY_NAME: 'Dashboard',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NAME: 'home',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/home.png`
  },
  DASHBOARD_MODULE: {
    PATH: '/dashboard',
    FULL_PATH: '/dashboard',
    DISPLAY_NAME: 'Dashboard',
    NAME: 'dashboard',
    IS_MENU: false,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    CHILDREN: {
      HOME: {
        PATH: '/home',
        FULL_PATH: '/dashboard/home',
        NAME: 'Home',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      }
    }
  },
  AUTH_MODULE: {
    PATH: '/auth',
    FULL_PATH: '/auth',
    NAME: 'auth',
    IS_MENU: false,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    CHILDREN: {
      LOGIN: {
        PATH: '/login',
        FULL_PATH: '/auth/login',
        NAME: 'Sign in',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      },
      RESET_PASSWORD: {
        PATH: '/reset-password',
        FULL_PATH: '/auth/reset-password',
        NAME: 'Reset password',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      },
      FORGOT_PASSWORD: {
        PATH: '/forgot-password',
        FULL_PATH: '/auth/forgot-password',
        NAME: 'Forgot password',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      },
      UNAUTHORIZED: {
        PATH: '/unauthorized',
        FULL_PATH: '/auth/unauthorzied',
        NAME: 'Unauthorized',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false
      }
    }
  },
  UNIT_TYPE_MODULE: {
    PATH: '/unit-types',
    FULL_PATH: '/unit-types',
    DISPLAY_NAME: 'Unit Types',
    NAME: 'unit-types',
    IS_MENU: false,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/area.png`,
    PERMITTED_ROLES: [UserRole.PARTNER_MANAGER, UserRole.ADMIN],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/unit-types/list',
        NAME: 'projects List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.PARTNER_MANAGER, UserRole.ADMIN]
      },
      EDIT: {
        PATH: '/edit/:id',
        FULL_PATH: '/unit-types/edit/:id',
        NAME: 'Edit project',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.PARTNER_MANAGER, UserRole.ADMIN]
      }
    }
  },
  RESERVATION_MODULE: {
    PATH: '/reservations',
    FULL_PATH: '/reservations',
    DISPLAY_NAME: 'Reservations',
    NAME: 'reservations',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NOTIFICATIONS_KEY: 'reservations',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/reservation.png`,
    PERMITTED_ROLES: [UserRole.PARTNER_MANAGER, UserRole.ADMIN],
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/reservations/list',
        NAME: 'Reservations List',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.PARTNER_MANAGER, UserRole.ADMIN]
      },
      EDIT: {
        PATH: '/edit/:id',
        FULL_PATH: '/reservations/edit/:id',
        NAME: 'Edit Reservation',
        IS_MENU: false,
        IS_MENU_LOOKUP: false,
        IS_MENU_USERS: false,
        PERMITTED_ROLES: [UserRole.PARTNER_MANAGER, UserRole.ADMIN]
      }
    }
  }
};

export const SETTINGS_ROUTES: RouteConfig = {
  ACCOUNT_SETTINGS_MODULE: {
    PATH: '/settings',
    FULL_PATH: '/setting',
    DISPLAY_NAME: 'Account Settings',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NAME: 'account settings',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/settings.png`
  },
  LOGOUT_MODULE: {
    PATH: '/logout',
    FULL_PATH: '/logout',
    DISPLAY_NAME: 'Logout',
    IS_MENU: true,
    IS_MENU_LOOKUP: false,
    IS_MENU_USERS: false,
    NAME: 'logout',
    ICON: `${PUBLIC_PATH}/assets/pngs/side-nav/logout.png`
  }
};
