import { PUBLIC_PATH } from './routes-config';

export const FILE_TYPES = {
  JPG: {
    image: `${PUBLIC_PATH}/assets/pngs/jpg-icon.png`
  },
  PNG: {
    image: `${PUBLIC_PATH}/assets/pngs/png-icon.png`
  },
  JPEG: {
    image: `${PUBLIC_PATH}/assets/pngs/jpeg-icon.webp`
  },
  PDF: {
    image: `${PUBLIC_PATH}/assets/pngs/pdf-icon.png`
  }
};
