import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { UserRole } from '../../../enums/user-role.enum';
import { hasRole } from '../../helpers/auth-helper/auth-helper.service';
import { Unauthorized } from '../../../components/core-ui/unauthorized/unauthorized.component';
import { RootState } from '../../../redux/reducers';

/**
 * AuthorizationComponentProps interface
 */
interface AuthorizationComponentProps {
  allowedRoles: UserRole[];
  component: React.ComponentType<any>;
  hideContent: boolean;
  // Redux injected props
  isAuthenticated: boolean;
}

/**
 * Authorization functional component
 */
const AuthorizationComponent: React.FC<AuthorizationComponentProps> = ({
  allowedRoles,
  hideContent,
  component: Component
}) => {
  // console.log('AuthorizationComponent');
  return hasRole(allowedRoles) ? (
    <Component />
  ) : hideContent ? (
    <></>
  ) : (
    <Unauthorized config={{ hideHomeButton: true }} />
  );
};

function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken,
    userRole: state.auth.role,
    userEmail: state.auth.email
  };
}

const ConnectedAuthorizationComponent = connect(mapStateToProps)(AuthorizationComponent);

/**
 * withAuthorization higher order function
 */
export const withAuthorization = (allowedRoles: UserRole[], hideContent?: boolean) => {
  // console.log('withAuthorization', allowedRoles);
  return (component: React.ComponentType<any>) => () =>
    (
      <ConnectedAuthorizationComponent
        allowedRoles={allowedRoles}
        hideContent={hideContent || false}
        component={component}
      />
    );
};
