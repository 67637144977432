import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Redirect } from 'react-router';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { PUBLIC_PATH, APP_ROUTES, SETTINGS_ROUTES } from './shared/config/routes-config';
import ReduxStore from './shared/redux/store';
import ReactNotification from 'react-notifications-component';

// import global styles
import './index.scss';
import 'react-notifications-component/dist/theme.css';
// import tracking section
import { initializeTrackingTools } from './shared/services/utils/tracking/tracking.service';
// import i18n config
import { initializeI18n } from './shared/config/i18n.config';
import { AuthModule } from './AuthModule/auth.module';
import { refreshUserSession, getUserNotification } from './shared/services/data/auth.data';
import DefaultLayout from './shared/layouts/default/default.layout';
import BlankLayout from './shared/layouts/blank/blank.layout';
import { UnitTypeModule } from './UnitTypeModule/unit-type.module';
import { DashboardModule } from './DashboardModule/dashboard.module';
import { ReservationModule } from './ReservationModule/reservation.module';
import { AuthStatus } from './shared/enums/auth.status.enum';
import { RouteConfig } from './shared/interfaces/routes-config.interface';
import { UserRole } from './shared/enums/user-role.enum';

// import app modules

const store = ReduxStore;
initializeTrackingTools();
initializeI18n();
const history = createBrowserHistory({ basename: PUBLIC_PATH });
// refresh user token and check validity
refreshUserSession().then((response) => {
  if (
    response === AuthStatus.UNAUTHORIZED &&
    window.location.href.indexOf(
      (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH
    ) < 0
  ) {
    console.log('User not premitted to access partner page');
    window.location.href =
      PUBLIC_PATH + (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH;
    // setTimeout(() => {
    //   history.push((APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH);
    // }, 500);
  }
});
ReactDOM.render(
  <React.StrictMode>
    <ReactNotification />
    <Provider store={store}>
      <Router history={history}>
        <BrowserRouter basename={PUBLIC_PATH}>
          <Switch>
            <Route exact={true} path="/">
              <Redirect to="/home" />
            </Route>
            <Route exact={true} path="/home">
              <Redirect to={APP_ROUTES.DASHBOARD_MODULE.PATH} />
            </Route>
            <BlankLayout
              authenticationRequired={false}
              component={AuthModule}
              path={APP_ROUTES.AUTH_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[UserRole.PARTNER_MANAGER, UserRole.ADMIN]}
              component={DashboardModule}
              path={APP_ROUTES.DASHBOARD_MODULE.PATH}
            />
            <DefaultLayout
              authenticationRequired={true}
              permittedList={[UserRole.PARTNER_MANAGER, UserRole.ADMIN]}
              component={ReservationModule}
              path={APP_ROUTES.RESERVATION_MODULE.PATH}
            />
            {/* <DefaultLayout
              authenticationRequired={true}
              permittedList={[UserRole.PARTNER_MANAGER, UserRole.ADMIN]}
              component={UnitTypeModule}
              path={APP_ROUTES.UNIT_TYPE_MODULE.PATH}
            /> */}
          </Switch>
        </BrowserRouter>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
