import React from 'react';
import styles from './filter-selector.module.scss';

export interface FilterSelectorOption {
  key: string;
  value: string;
  text: string;
  count: number;
}
interface FilterSelectorProps {
  answers: FilterSelectorOption[];
  choices: FilterSelectorOption[];
  className?: string;
  onSelection: (answer: FilterSelectorOption, checked: boolean) => void;
}

export const FilterSelector = (props: FilterSelectorProps): JSX.Element => {
  return (
    <>
      <div className={`${styles['filter-selector']} ${props.className || ''}`}>
        {props.answers.map((answer, index) => (
          <span
            key={index}
            className={`${styles['filter-selector__item']} ${
              index < props.answers.length - 1 ? styles['filter-selector__item--separator'] : ''
            }
               ${
                 props.choices.map((choice) => choice.value).includes(answer.value)
                   ? styles['filter-selector__item--selected']
                   : ''
               }`}
            onClick={() => {
              const isChecked = props.choices.map((choice) => choice.value).includes(answer.value);
              props.onSelection(answer, !isChecked);
            }}
          >
            {answer.text}
            {!!answer.count && answer.count !== 0 && (
              <span
                className={styles['filter-selector__item__count']}
              >{`  (${answer.count})`}</span>
            )}
          </span>
        ))}
      </div>
    </>
  );
};
