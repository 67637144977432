import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../shared/components/core-ui/button/button.component';
import { connect } from 'react-redux';
import styles from './reset-password.module.scss';
import { store } from 'react-notifications-component';
import { useHistory, useLocation } from 'react-router';
import {
  InputText,
  InputTextType
} from '../../../shared/components/core-ui/input-text/input-text.component';
import { FormElementData } from '../../../shared/interfaces/form-element-data.interface';
import { APP_ROUTES, PUBLIC_PATH } from '../../../shared/config/routes-config';
import { RouteConfig } from '../../../shared/interfaces/routes-config.interface';
import { changeUserPassword } from '../../../shared/services/data/auth.data';
import { getModuleName } from '../../shared/services/auth.service';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { setUserToken } from '../../../shared/services/helpers/auth-helper/auth-helper.service';
import { UserAuthUIModel } from '../../../shared/ui-models/user-auth.ui-model';
import { RootState } from '../../../shared/redux/reducers';
import {
  RESET_PASSWORD_TOKEN_QUERY_PARAM,
  TARGET_URL_QUERY_PARAM
} from '../../../shared/config/auth.config';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import { trackEvent, trackPage } from '../../../shared/services/utils/tracking/tracking.service';

export type ResetPasswordContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component ResetPasswordContainer
 * @param {ResetPasswordContainerProps} holding question text
 */
export const ResetPasswordContainer: React.FC<ResetPasswordContainerProps> = ({
  isAuthenticated
}) => {
  const [targetRoute, setTargetRoute] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [matchingPassword, setMatchingPassword] = useState(true);
  const [showGeneralError, setShowGeneralError] = useState(false);
  const [passwordField, setPasswordField] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [passwordRepeatField, setPasswordRepeatField] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    trackPage('reset-password');
    const targetUrl = new URLSearchParams(location.search).get(TARGET_URL_QUERY_PARAM);
    const token = new URLSearchParams(location.search).get(RESET_PASSWORD_TOKEN_QUERY_PARAM);
    if (targetUrl) setTargetRoute(targetUrl);
    /** commented until discussion finalized  */
    // if (isAuthenticated) {
    //   trackEvent('reset-password|authenticated-auto-redirect');
    // if (targetRoute) {
    //   history.push(targetRoute);
    // } else {
    //   history.push(APP_ROUTES.HOME_MODULE.FULL_PATH);
    // } else {
    // }
    if (!token) {
      trackEvent('error-no-token', {
        category: 'auth',
        label: 'reset-password'
      });
      setShowGeneralError(true);
    } else {
      setToken(token);
    }
    // }
    return () => {
      store.removeNotification('reset-password-failed');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * function to handle sign up submission
   */
  const submitHandler = () => {
    if (
      passwordField.value &&
      passwordField.isValid &&
      passwordRepeatField.value &&
      passwordRepeatField.isValid &&
      passwordField.value === passwordRepeatField.value
    ) {
      setIsLoading(true);
      store.removeNotification('reset-password-failed');
      changeUserPassword(token, passwordField.value as string, getModuleName()).then(
        (response: UserAuthUIModel | ErrorInfo) => {
          if (isErrorInfo(response)) {
            setTimeout(() => {
              setIsLoading(false);
              store.addNotification({
                id: 'reset-password-failed',
                title: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.CONTACT_US_ERROR_TITLE'),
                message: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.CONTACT_US_ERROR_MESSAGE'),
                type: 'danger',
                insert: 'top',
                container: 'top-center',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut']
              });
            }, 500);
            trackEvent('failed', {
              category: 'auth',
              label: 'reset-password'
            });
          } else {
            setIsLoading(false);
            trackEvent('success', {
              category: 'auth',
              label: 'reset-password'
            });
            setUserToken(response);
            window.scrollTo({
              behavior: 'smooth',
              top: 0 // header height
            });
            if (targetRoute) {
              history.push(targetRoute);
            } else {
              history.push(APP_ROUTES.HOME_MODULE.FULL_PATH);
            }
          }
        }
      );
    }
  };

  return (
    <div className={styles['reset-password-container']}>
      {showGeneralError ? (
        <div className={styles['reset-password-container__illustration']}>
          <img
            src={`${PUBLIC_PATH}/assets/jpeg/no-results-found.jpg`}
            alt="invalid-link"
            className={styles['reset-password-container__illustration__image']}
          />
          <div className={styles['reset-password-container__illustration__message']}>
            {t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.BROKEN_LINK')}
          </div>
          <div className={styles['reset-password-container__illustration__action-buttons']}>
            <Button
              onClick={() => {
                if (targetRoute) {
                  history.push({
                    pathname: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH,
                    search: `?${TARGET_URL_QUERY_PARAM}=${targetRoute}`
                  });
                } else {
                  history.push({
                    pathname: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH
                  });
                }
              }}
              tracking={{
                action: 'error-login-clicked',
                category: 'auth',
                label: 'reset-password'
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              {t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.RESET_PASSWORD_BUTTON')}
            </Button>
            <Button
              onClick={() => {
                if (targetRoute) {
                  history.push({
                    pathname: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH,
                    search: `?${TARGET_URL_QUERY_PARAM}=${targetRoute}`
                  });
                } else {
                  history.push({
                    pathname: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH
                  });
                }
              }}
              type={ButtonType.primaryInverted}
              tracking={{
                action: 'login-button',
                category: 'auth',
                label: 'reset-password'
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              {t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.LOGIN_BUTTON')}
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles['reset-password-container__wrapper']}>
          <img
            className={styles['reset-password-container__wrapper__user-icon']}
            src={`${PUBLIC_PATH}/assets/svgs/user.svg`}
            alt="login-user"
          />
          <h1 className={styles['reset-password-container__wrapper__title']}>
            {t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.TITLE')}
          </h1>
          <p className={styles['reset-password-container__wrapper__sub_title']}>
            {t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.SUB_TITLE')}
          </p>
          <p className={styles['reset-password-container__wrapper__label']}>
            {t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PASSWORD_LABEL')}
          </p>
          <InputText
            config={{
              autoFocus: true,
              type: InputTextType.password,
              minLength: 6,
              required: true
            }}
            data={{
              placeholder: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PASSWORD_PLACEHOLDER'),
              errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PASSWORD_ERROR_MESSAGE'),
              defaultValue: ''
            }}
            eventHandlers={{
              onChangeHandler: (value, isValid) => {
                setPasswordField({ value, isValid });
                setMatchingPassword(value === passwordRepeatField.value);
              },
              onEnterPressedHandler: submitHandler
            }}
          />
          <p className={styles['reset-password-container__wrapper__label']}>
            {t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.REPEAT_PASSWORD_LABEL')}
          </p>
          <InputText
            config={{
              type: InputTextType.password,
              // minLength: 6,
              required: true,
              error: !matchingPassword
            }}
            data={{
              placeholder: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.REPEAT_PASSWORD_PLACEHOLDER'),
              errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.REPEAT_PASSWORD_ERROR_MESSAGE'),
              defaultValue: ''
            }}
            eventHandlers={{
              onChangeHandler: (value, isValid) => {
                setPasswordRepeatField({ value, isValid });
                setMatchingPassword(value === passwordField.value);
              },
              onEnterPressedHandler: submitHandler
            }}
          />

          <div className={styles['reset-password-container__wrapper__submit-button']}>
            <Button
              onClick={submitHandler}
              tracking={{
                action: 'submit',
                category: 'auth',
                label: 'reset-password'
              }}
              size={ButtonSize.full}
              disabled={!passwordField.isValid || !passwordRepeatField.isValid || !matchingPassword}
              loading={isLoading}
            >
              {t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.SUBMIT_BUTTON')}
            </Button>
          </div>
          <div className={styles['reset-password-container__wrapper__registration']}>
            <div className={styles['reset-password-container__wrapper__registration__message']}>
              {t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.LOGIN_MESSAGE')}
            </div>
            <div
              className={styles['reset-password-container__wrapper__registration__link']}
              onClick={() => {
                trackEvent('login-clicked', {
                  category: 'auth',
                  label: 'reset-password'
                });
                if (targetRoute) {
                  history.push({
                    pathname: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH,
                    search: `?${TARGET_URL_QUERY_PARAM}=${targetRoute}`
                  });
                } else {
                  history.push({
                    pathname: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH
                  });
                }
              }}
            >
              {t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.LOGIN_LINK')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ResetPasswordContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
