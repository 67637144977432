import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styles from './side-menu.module.scss';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import useTabletWindowResizer from '../../../hooks/window-resizer/tablet-resizer.hook';

import { APP_ROUTES, SETTINGS_ROUTES } from '../../../config/routes-config';
import { RouteConfigItem } from '../../../interfaces/routes-config.interface';
import { UserNotificationsModel } from '../../../models/user-notifications.model';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      },
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: '1.5rem',
      'padding-left': '1rem',
      width: '100%'
    },
    toolbarRoot: {
      width: '100%'
    },
    drawerPaper: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth
      },
      [theme.breakpoints.up('md')]: {
        width: drawerWidth
      },
      [theme.breakpoints.up('md')]: {
        width: drawerWidth
      },
      backgroundColor: '#566346',
      color: 'white'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    listItem: {
      fontSize: '1.6rem'
    }
  })
);

export interface SideMenuProps {
  userEmail?: string;
  userImage?: string;
  userRole: UserRole;
  userNotification?: UserNotificationsModel;
  toggleSideMenu?: boolean;
  logoutHandler?: () => void;
}

export const SideMenu: React.FC<SideMenuProps> = ({
  userEmail,
  userImage,
  userRole,
  userNotification,
  toggleSideMenu,
  logoutHandler
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const classes = useStyles();
  const theme = useTheme();
  const [menuItemsList, setMenuItemList] = useState<RouteConfigItem[]>([]);
  const [menuLookupList, setMenuLookupList] = useState<RouteConfigItem[]>([]);
  const [userLookupList, setUserLookupList] = useState<RouteConfigItem[]>([]);
  const [settingsMenuItemsList, setSettingsMenuItemList] = useState<RouteConfigItem[]>([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isTabletView = useTabletWindowResizer();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    getMenuList();
  }, []);

  useEffect(() => {
    setMobileOpen(toggleSideMenu || false);
  }, [toggleSideMenu]);

  const getMenuList = () => {
    const routeMenuList: RouteConfigItem[] = [];
    Object.keys(APP_ROUTES).forEach((routeItemKey: string) => {
      if (APP_ROUTES[routeItemKey].IS_MENU) {
        routeMenuList.push(APP_ROUTES[routeItemKey]);
      }
    });

    setMenuItemList(routeMenuList);
    const routeMenuLookupList: RouteConfigItem[] = [];
    Object.keys(APP_ROUTES).forEach((routeItemKey: string) => {
      if (APP_ROUTES[routeItemKey].IS_MENU_LOOKUP) {
        routeMenuLookupList.push(APP_ROUTES[routeItemKey]);
      }
    });

    setMenuLookupList(routeMenuLookupList);

    const routeUserLookupList: RouteConfigItem[] = [];
    Object.keys(APP_ROUTES).forEach((routeItemKey: string) => {
      if (APP_ROUTES[routeItemKey].IS_MENU_USERS) {
        routeUserLookupList.push(APP_ROUTES[routeItemKey]);
      }
    });

    setUserLookupList(routeUserLookupList);
    const settingsMenuList: RouteConfigItem[] = [];
    Object.keys(SETTINGS_ROUTES).forEach((routeItemKey: string) => {
      if (SETTINGS_ROUTES[routeItemKey].IS_MENU) {
        settingsMenuList.push(SETTINGS_ROUTES[routeItemKey]);
      }
    });
    setSettingsMenuItemList(settingsMenuList);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleLogout = () => {
    handleClose();
    if (logoutHandler) logoutHandler();
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to handle closing user menu
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        {userEmail && (
          <div className={styles['side-menu__user-actions']}>
            {userImage ? (
              <img alt="user-avatar" src={userImage}></img>
            ) : (
              <div className={styles['side-menu__user-actions__avatar-wrapper']}>
                <div className={styles['side-menu__user-actions__avatar-wrapper__initials']}>
                  {userEmail.charAt(0).toUpperCase()}
                </div>
              </div>
            )}
            <Button aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
              <span className={styles['side-menu__user-actions__button']}>{userEmail}</span>
              <ArrowDropDownIcon color="inherit" fontSize={'small'} />
            </Button>
            <Menu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              id="user-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>{t('SHARED.HEADER.LOGOUT_BUTTON')}</MenuItem>
            </Menu>
          </div>
        )}
      </div>
      <Divider />
      <List>
        {menuItemsList.map((listItem, index) =>
          (listItem &&
            listItem?.PERMITTED_ROLES &&
            listItem?.PERMITTED_ROLES.indexOf(userRole) > -1) ||
          !listItem?.PERMITTED_ROLES ? (
            <ListItem
              button
              key={listItem.NAME}
              onClick={() => {
                history.push(listItem.FULL_PATH);
              }}
            >
              {listItem.ICON && (
                <img
                  alt={`icon-${listItem.DISPLAY_NAME}`}
                  className={styles['side-menu__link-item__icon']}
                  src={listItem.ICON}
                />
              )}
              <ListItemText
                classes={{
                  primary: classes.listItem
                }}
                primary={listItem.DISPLAY_NAME}
              />
              {listItem.NEW_LABEL && (
                <div className={styles['side-menu__link-item__new-label']}>NEW</div>
              )}

              {/* {listItem &&
              listItem.NOTIFICATIONS_KEY &&
              userNotification &&
              userNotification[listItem.NOTIFICATIONS_KEY] &&
              userNotification[listItem.NOTIFICATIONS_KEY] > 0 && ( */}
              {listItem.NOTIFICATIONS_KEY &&
                userNotification &&
                typeof userNotification[listItem.NOTIFICATIONS_KEY] === 'number' &&
                userNotification[listItem.NOTIFICATIONS_KEY] > 0 && (
                  <div className={styles['side-menu__link-item__notification']}>
                    {userNotification[listItem.NOTIFICATIONS_KEY]}
                  </div>
                )}
              {/* )} */}
            </ListItem>
          ) : null
        )}
      </List>
      <Divider />
      <List>
        {menuLookupList.map((listItem, index) =>
          (listItem &&
            listItem?.PERMITTED_ROLES &&
            listItem?.PERMITTED_ROLES.indexOf(userRole) > -1) ||
          !listItem?.PERMITTED_ROLES ? (
            <ListItem
              button
              key={listItem.NAME}
              onClick={() => {
                history.push(listItem.FULL_PATH);
              }}
            >
              {listItem.ICON && (
                <img
                  alt={`icon-${listItem.DISPLAY_NAME}`}
                  className={styles['side-menu__link-item__icon']}
                  src={listItem.ICON}
                />
              )}
              <ListItemText
                classes={{
                  primary: classes.listItem
                }}
                primary={listItem.DISPLAY_NAME}
              />
              {listItem.NEW_LABEL && (
                <div className={styles['side-menu__link-item__new-label']}>NEW</div>
              )}
            </ListItem>
          ) : null
        )}
      </List>
      <Divider />
      <List>
        {userLookupList.map((listItem, index) =>
          (listItem &&
            listItem?.PERMITTED_ROLES &&
            listItem?.PERMITTED_ROLES.indexOf(userRole) > -1) ||
          !listItem?.PERMITTED_ROLES ? (
            <ListItem
              button
              key={listItem.NAME}
              onClick={() => {
                history.push(listItem.FULL_PATH);
              }}
            >
              {listItem.ICON && (
                <img
                  alt={`icon-${listItem.DISPLAY_NAME}`}
                  className={styles['side-menu__link-item__icon']}
                  src={listItem.ICON}
                />
              )}
              <ListItemText
                classes={{
                  primary: classes.listItem
                }}
                primary={listItem.DISPLAY_NAME}
              />
              {listItem.NEW_LABEL && (
                <div className={styles['side-menu__link-item__new-label']}>NEW</div>
              )}
            </ListItem>
          ) : null
        )}
      </List>
      <Divider />
      <List>
        {settingsMenuItemsList.map((listItem, index) => (
          <ListItem
            button
            key={listItem.NAME}
            onClick={() => {
              if (listItem.NAME === 'logout') {
                handleLogout();
              } else history.push(listItem.FULL_PATH);
            }}
          >
            {listItem.ICON && (
              <img
                alt={`icon-${listItem.DISPLAY_NAME}`}
                className={styles['side-menu__link-item__icon']}
                src={listItem.ICON}
              />
            )}
            <ListItemText
              classes={{
                primary: classes.listItem
              }}
              primary={listItem.DISPLAY_NAME}
            />
            {listItem.NEW_LABEL && (
              <div className={styles['side-menu__link-item__new-label']}>NEW</div>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      {isTabletView ? (
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      ) : (
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      )}
    </nav>
  );
};
