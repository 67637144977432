import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import HomeContainer from './containers/home/home.container';
/**
 * function DashboardModule components
 */
export const DashboardModule: React.FC = () => {
  return (
    <>
      <Route exact path={APP_ROUTES.DASHBOARD_MODULE.FULL_PATH}>
        <Redirect to={(APP_ROUTES.DASHBOARD_MODULE.CHILDREN as RouteConfig).HOME.FULL_PATH} />
      </Route>
      <Route
        path={(APP_ROUTES.DASHBOARD_MODULE.CHILDREN as RouteConfig).HOME.FULL_PATH}
        component={HomeContainer}
      />
    </>
  );
};
