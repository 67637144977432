import React, { useEffect, useState } from 'react';
import {
  FilterSelector,
  FilterSelectorOption
} from '../../../../../shared/components/group-ui/filter-selector/filter-selector.component';
import moment from 'moment';
import styles from './reservations-updates.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { RouteConfig } from '../../../../../shared/interfaces/routes-config.interface';
import { APP_ROUTES, PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import { getAllReservations } from '../../../../../ReservationModule/shared/services/data.service';
import { ReservationStatus } from '../../../../../shared/enums/reservation-status';
import { isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { ReservationUiModel } from '../../../../../shared/ui-models/reservation.ui-model';
import { ReservationSource } from '../../../../../shared/enums/reservation-souce.enum';
import { ReservationChannel } from '../../../../../shared/enums/reservation-channel.enum';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
      // backgroundColor: '#ffa358'
    }
  }
});
/**
 * functional component ReservationsUpdates
 * @param {ReservationsUpdatesProps}
 */
export const ReservationsUpdates: React.FC = () => {
  const [arrivalReservations, setArrivalReservations] = useState<ReservationUiModel[]>([]);
  const [departureReservations, setDepartureReservations] = useState<ReservationUiModel[]>([]);
  const [newReservations, setNewReservations] = useState<ReservationUiModel[]>([]);
  const [currentlyHostingReservations, setCurrentlyHostingReservations] = useState<
    ReservationUiModel[]
  >([]);
  const classes = useRowStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterSelectorOption[]>([
    {
      key: '1',
      text: 'Upcoming in the next 3 days',
      value: 'upcoming',
      count: arrivalReservations?.length || 0
    }
  ]);
  const [answers] = useState<FilterSelectorOption[]>([
    {
      key: '1',
      text: 'Currently Hosting',
      value: 'hosting',
      count: currentlyHostingReservations?.length || 0
    },
    {
      key: '2',
      text: 'Upcoming in the next 3 days',
      value: 'upcoming',
      count: arrivalReservations?.length || 0
    },
    {
      key: '3',
      text: 'Departures in the next 3 days',
      value: 'departure',
      count: departureReservations?.length || 0
    }
  ]);

  const getReservationsUpdates = (range: string) => {
    const statusQuery =
      range === 'hosting'
        ? [ReservationStatus.IN_HOUSE]
        : [ReservationStatus.CHECKED_IN, ReservationStatus.CHECKED_OUT];
    getAllReservations({
      range,
      statusQuery
    }).then((response) => {
      // setLoadingData(false);
      if (!isErrorInfo(response)) {
        if (range === 'upcomingCheckIns') setArrivalReservations(response);
        if (range === 'upcomingDepatures') setDepartureReservations(response);
        if (range === 'createdRecently') setNewReservations(response);
        if (range === 'hosting') setCurrentlyHostingReservations(response);
      }
    });
  };
  useEffect(() => {
    setFilters([answers[0]]);
    getReservationsUpdates('upcomingCheckIns');
    getReservationsUpdates('upcomingDepatures');
    getReservationsUpdates('createdRecently');
    getReservationsUpdates('hosting');
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderReservations = (reservation: ReservationUiModel[]) => {
    return (
      <div className={styles['reservations-updates--table']}>
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>Reservation #</TableCell>
                <TableCell>Guest Name</TableCell>
                <TableCell>Room Name</TableCell>
                <TableCell>Check In</TableCell>
                <TableCell>Check Out</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservation?.length > 0 &&
                reservation?.map((reservation: ReservationUiModel) => (
                  <ReservationRow key={reservation.id} reservation={reservation} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  function ReservationRow(props: { reservation: ReservationUiModel }) {
    const { reservation } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow
          className={
            reservation.status === ReservationStatus.CHECKED_IN_STARTED
              ? selectedClasses.root
              : classes.root
          }
          onClick={() => {
            window.open(
              PUBLIC_PATH +
                (APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH.replace(
                  ':id',
                  reservation.id
                ),
              '_blank'
            );
          }}
        >
          <TableCell>#{reservation.id}</TableCell>
          <TableCell>{reservation.primaryGuestName}</TableCell>
          <TableCell>{reservation.unitName.replace(/(^.*\(|\).*$)/g, '')}</TableCell>
          <TableCell>{new Date(reservation.checkInDate).toDateString()}</TableCell>
          <TableCell>{new Date(reservation.checkOutDate).toDateString()}</TableCell>
        </TableRow>
        <TableRow></TableRow>
      </>
    );
  }

  return (
    <div className={styles['reservations-updates']}>
      <FilterSelector
        choices={filters}
        onSelection={(answer, checked) => {
          if (checked) {
            setFilters([answer]);
          }
        }}
        answers={answers}
      />
      {isLoading ? (
        <div className={styles['reservations-updates__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          {filters[0].value === 'upcoming' ? (
            arrivalReservations.length ? (
              renderReservations(arrivalReservations)
            ) : (
              <div className={styles['reservations-updates__no-records']}>
                <img src={`${PUBLIC_PATH}/assets/svgs/no-data.svg`} alt="no-data" /> No Data
                Available
              </div>
            )
          ) : (
            <></>
          )}

          {filters[0].value === 'departure' ? (
            departureReservations.length ? (
              renderReservations(departureReservations)
            ) : (
              <div className={styles['reservations-updates__no-records']}>
                <img src={`${PUBLIC_PATH}/assets/svgs/no-data.svg`} alt="no-data" /> No Data
                Available
              </div>
            )
          ) : (
            <></>
          )}
          {filters[0].value === 'new' ? (
            newReservations.length ? (
              renderReservations(newReservations)
            ) : (
              <div className={styles['reservations-updates__no-records']}>
                <img src={`${PUBLIC_PATH}/assets/svgs/no-data.svg`} alt="no-data" /> No Data
                Available
              </div>
            )
          ) : (
            <></>
          )}
          {filters[0].value === 'hosting' ? (
            currentlyHostingReservations.length ? (
              renderReservations(currentlyHostingReservations)
            ) : (
              <div className={styles['reservations-updates__no-records']}>
                <img src={`${PUBLIC_PATH}/assets/svgs/no-data.svg`} alt="no-data" /> No Data
                Available
              </div>
            )
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
