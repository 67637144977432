import { ReservationModel } from '../models/reservation.model';
import { ReservationUiModel } from '../ui-models/reservation.ui-model';

function mapToUI(reservationData: ReservationModel): ReservationUiModel {
  return {
    id: reservationData.id,
    checkInDate: reservationData.checkInDate,
    checkOutDate: reservationData.checkOutDate,
    status: reservationData.status,
    primaryGuestName: reservationData.primaryGuestName,
    unitTypeId: reservationData.unitTypeId,
    checkinExpiresAt: reservationData.checkinExpiresAt || 0,
    roomId: reservationData.roomId,
    propertyName: reservationData.propertyName || '',
    source: reservationData.source,
    channel: reservationData.channel,
    propertyId: reservationData.propertyId || '',
    unitName: reservationData.unitName || '',
    area: reservationData.area || '',
    passKey: reservationData.passKey || '',
    guestIds: reservationData.guestIds || [],
    reservationUrl: reservationData.reservationUrl || ''
  };
}
function mapToUIList(reservationList: ReservationModel[]): ReservationUiModel[] {
  return reservationList.map(mapToUI);
}

export default {
  mapToUI,
  mapToUIList
};
