import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import ForgotPasswordContainer from './containers/forgot-password/forgot-password.container';
import LoginContainer from './containers/login/login.container';
import ResetPasswordContainer from './containers/reset-password/reset-password.container';
import UnauthorizedContainer from './containers/unauthorized/unauthorized.container';
/**
 * function AuthModule components
 */
export const AuthModule: React.FC = () => {
  return (
    <>
      <Route exact path={APP_ROUTES.AUTH_MODULE.FULL_PATH}>
        <Redirect to={(APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH} />
      </Route>
      <Route
        path={(APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH}
        component={LoginContainer}
      />
      <Route
        path={(APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).RESET_PASSWORD.FULL_PATH}
        component={ResetPasswordContainer}
      />
      <Route
        path={(APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).FORGOT_PASSWORD.FULL_PATH}
        component={ForgotPasswordContainer}
      />
      <Route
        path={(APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH}
        component={UnauthorizedContainer}
      />
    </>
  );
};
