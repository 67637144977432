import React from 'react';
import { APP_ROUTES, PUBLIC_PATH } from '../../../config/routes-config';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './global-header.component.scss';
import { Link } from 'react-router-dom';
import { NavLinkItem } from '../../../interfaces/nav-link.interface';
import { RootState } from '../../../redux/reducers';
import { connect } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import useWindowResizer from '../../../hooks/window-resizer/tablet-resizer.hook';
import { IconButton } from '@material-ui/core';

/**
 * interface GlobalHeaderProps
 */
export interface GlobalHeaderProps {
  userEmail?: string;
  backLink?: NavLinkItem | null;
  logoutHandler?: () => void;
  toggleMobileMenu: () => void;
}

/**
 *
 * @param userEmail logged in user email
 * @param logoutHandler to handle when user logout
 * @param children parent component children
 */
export const GlobalHeader: React.FC<GlobalHeaderProps> = ({
  userEmail,
  backLink,
  children,
  logoutHandler,
  toggleMobileMenu
}) => {
  const isTabletView = useWindowResizer();

  return (
    <header className="body__header">
      {isTabletView && backLink ? (
        <>
          <Link
            to={{
              pathname: backLink.navLink
            }}
          >
            <div className="back-link">
              <ArrowBackIosIcon color="inherit" fontSize="inherit" /> {backLink.title}
            </div>
          </Link>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleMobileMenu}
          >
            <MenuIcon />
          </IconButton>
        </>
      ) : (
        <>
          <Link
            to={{
              pathname: APP_ROUTES.HOME_MODULE.FULL_PATH
            }}
          >
            <img
              src={`${PUBLIC_PATH}/assets/pngs/birdnest-logo.png`}
              alt="delete"
              className="logo"
            />
          </Link>
          {isTabletView && (
            <div className={'header-container__menu-toggle'}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={toggleMobileMenu}
                style={{
                  fontSize: '2rem'
                }}
              >
                <MenuIcon fontSize="inherit" />
              </IconButton>
            </div>
          )}
        </>
      )}
      <div className="header-container">
        {children ? <div className="children-wrapper">{children}</div> : ''}
      </div>
    </header>
  );
};

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    backLink: state.appConfig.headerBackLink
  };
}

export default connect(mapStateToProps)(GlobalHeader);
