import React, { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import { connect } from 'react-redux';
import styles from './reservation-edit.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import MoneyIcon from '@material-ui/icons/Money';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Accordion from '@material-ui/core/Accordion';
import ReplayIcon from '@material-ui/icons/Replay';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage, trackPurchase } from '../../../shared/services/utils/tracking/tracking.service';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getUnitTypeDetails } from '../../../shared/services/data/unit-type.data';
import { UnitTypeUiModel } from '../../../shared/ui-models/unit-type.ui-model';
import { getModuleName } from '../../shared/services/reservation.service';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import OtpInput from 'react-otp-input';
import {
  getOneReservations,
  getOneGuest,
  updateGuest,
  addGuest,
  updateOneReservations,
  updateOnePayment,
  createOnePayment,
  uploadPaymentReceipt,
  uploadGuestDocument,
  getBookOffer,
  reactivateOneReservations,
  addOneComment,
  generatePaymentLink,
  sendPayment
} from '../../shared/services/data.service';
import { ReservationDetailsUiModel } from '../../../shared/ui-models/reservation.ui-model';
import { RouteComponentProps } from 'react-router';
import Select, { ValueType } from 'react-select';
import { ReservationStatus } from '../../../shared/enums/reservation-status';
import ArrowForward from '@material-ui/icons/ArrowForward';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Tabs, TabsPosition } from '../../../shared/components/group-ui/tabs/tabs.component';
import EdiText from 'react-editext';
import { GuestUiModel } from '../../../shared/ui-models/guest.ui-model';
import moment from 'moment';
import { AddGuestModal } from './components/add-guest-modal/add-guest-modal.component';
import { PUBLIC_PATH } from '../../../shared/config/routes-config';
import { PERMISSIONS_LIST } from '../../../shared/config/permissions.config';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../shared/components/core-ui/button/button.component';
import { PaymentType } from '../../../shared/enums/payment-type.enum';
import { PaymentCollector } from '../../../shared/enums/payment-collector.enum';
import { PaymentStatus } from '../../../shared/enums/payment-status.enum';
import { PaymentModel } from '../../../shared/models/payment.model';
import { AddPaymentModal } from './components/add-payment-modal/add-payment-modal.component';
import { AddPaymentReceiptModal } from './components/add-payment-receipt-modal/add-payment-receipt-modal.component';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import NumberFormat from 'react-number-format';
import { PaymentUIModel } from '../../../shared/ui-models/payment.ui-model';
import Dropzone from 'react-dropzone-uploader';
import { AddCommentModal } from './components/add-comment-modal/add-comment-modal.component';
import { makeStyles } from '@material-ui/core';
import { hasAccess } from '../../../shared/services/helpers/auth-helper/auth-helper.service';
import { BookOfferResponseModel } from '../../../shared/ui-models/book.ui-model';
import { SelectAvailableRoomModal } from './components/select-available-room-modal/select-available-room-modal.component';
import { BookingRequestUiModel } from '../../../shared/ui-models/booking-request.ui-model';
import { getBookingRequestDetails } from '../../../shared/services/data/booking.data';
import { GuestDocumentType } from '../../../shared/models/guest.model';
import { FILE_TYPES } from '../../../shared/config/files-types.config';
import { PaymentOccurrenceMode } from '../../../shared/enums/payment-occurrence-mode.enum';
import { CommentsThread } from './components/comments-thread/comments-thread.component';
import { HistoryThread } from './components/history-thread/history-thread.component';
import { GeneratePaymentLinkModal } from './components/generate-payment-link-modal/generate-payment-link-modal.component';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EmailIcon from '@material-ui/icons/Email';
import { BookingMode } from '../../../shared/enums/booking-mode.enum';
import { SendPaymentLinkModal } from './components/send-payment-link-modal/send-payment-link-modal.component';
import { ReservationChannel } from '../../../shared/enums/reservation-channel.enum';
import { CURRENCY } from '../../../shared/enums/currency.enum';
import { ReservationSource } from '../../../shared/enums/reservation-souce.enum';

const collectorOption = [
  { label: 'Air bnb', value: PaymentCollector.AIRBNB },
  { label: 'Birdnest', value: PaymentCollector.BIRDNEST },
  { label: 'Booking', value: PaymentCollector.BOOKING },
  { label: 'Paymob', value: PaymentCollector.PAYMOB }
];
const paymentStatusOptions = [
  { label: 'Paid', value: PaymentStatus.COLLECTED },
  { label: 'Overdue', value: PaymentStatus.OVERDUE, isDisabled: true },
  { label: 'Not yet', value: PaymentStatus.PENDING, isDisabled: true },
  { label: 'Refunded', value: PaymentStatus.REFUNDED, isDisabled: true },
  { label: 'Canceled', value: PaymentStatus.CANCELED, isDisabled: true }
];
const paymentTypeOptions = [
  { label: 'Cash', value: PaymentType.CASH },
  { label: 'Online Payment', value: PaymentType.ONLINE_PAYMENT },
  { label: 'Bank Transfer', value: PaymentType.BANK_TRANSFER }
];

const useStyles = makeStyles({
  defaultRoot: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgb(187 186 186 / 50%)'
  },
  refundRoot: {
    backgroundColor: '#fedfa5',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgb(187 186 186 / 50%)'
  },
  disabledRoot: {
    backgroundColor: 'lightgrey',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgb(187 186 186 / 50%)'
  }
});
interface EditPropertyRouteParams {
  id: string;
}

export type ReservationsEditContainerProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<EditPropertyRouteParams>;

/**
 * functional component ReservationsEditContainer
 * @param {ReservationsEditContainerProps} holding question text
 */

export const ReservationsEditContainer: React.FC<ReservationsEditContainerProps> = ({
  match,
  isAuthenticated
}) => {
  // const isMobileView = useWindowResizer();
  // const { t } = useTranslation();
  const [reservation, setReservation] = useState<ReservationDetailsUiModel>();
  const [unitType, setUnitType] = useState<UnitTypeUiModel>();
  const [guest, setGuest] = useState<GuestUiModel>();
  const [bookoffer, setBookoffer] = useState<BookOfferResponseModel>();
  const [bookofferLoading, setBookofferLoading] = useState<boolean>(false);
  const [reservationStatus, setReservationStatus] = useState<string>();
  const [showAddGuestModal, setShowAddGuestModal] = useState<boolean>(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState<boolean>(false);
  const [showGeneratePaymentLinkModal, setShowGeneratePaymentLinkModal] = useState<boolean>(false);
  const [showSendPaymentLinkModal, setShowSendPaymentLinkModal] = useState<boolean>(false);
  const [showAddPaymentRecipetModal, setShowAddPaymentRecipetModal] = useState<boolean>(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState<boolean>(false);
  const [showReactivateModal, setShowReactivateModal] = useState<boolean>(false);
  const [activePayment, setActivePayment] = useState<PaymentModel>();
  const [commentValue, setCommentValue] = useState<string>('');
  const [skipEmail, setSkipEmail] = useState<boolean>(false);
  const [tabKey, setTabKey] = useState('Comments');
  const [passKey, setPassKey] = useState<string>('∗∗∗∗∗∗');
  const [isPassKeyDisabled, setIsPassKeyDisabled] = useState<boolean>(true);
  const [editPassKey, setEditPassKey] = useState<boolean>(false);
  const [passKeyLoading, setPassKeyLoading] = useState<boolean>(false);
  const [isPassportUploadLoading, setIsPassportUploadLoading] = useState<boolean>(false);
  const [isNationalIdUploadLoading, setIsNationalIdUploadLoading] = useState<boolean>(false);
  const [isMarriageUploadLoading, setIsMarriageUploadLoading] = useState<boolean>(false);
  const [activePaymentId, setActivePaymentId] = useState<string>('');
  const [isCopy, setIsCopy] = useState(false);

  const classes = useStyles();

  const reservationStatusColourStyles = (stateValue: any) => {
    return {
      singleValue: (styles: any) => ({ ...styles, color: 'inherit' }),
      control: (styles: any) => {
        const colorObj = getReservationStatusColor(stateValue);
        return {
          ...styles,
          ...colorObj
        };
      },
      option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        const colorObj = getReservationStatusColor(data.value);
        return {
          ...styles,
          ...colorObj
        };
      }
    };
  };
  const paymentStatusColourStyles = (stateValue: any) => {
    return {
      singleValue: (styles: any) => ({ ...styles, color: 'inherit' }),
      control: (styles: any) => {
        const colorObj = getPaymentStatusColor(stateValue);
        return {
          ...styles,
          ...colorObj
        };
      },
      option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        const colorObj = getPaymentStatusColor(data.value);
        return {
          ...styles,
          ...colorObj
        };
      }
    };
  };
  useEffect(() => {
    const id = match.params.id;
    trackPage('reservations-edit');
    getOneReservation(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getOneGuestObject(id: string) {
    getOneGuest(id).then((response: GuestUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setGuest(response);
      }
    });
  }
  function updateOneGuest(guest: GuestUiModel) {
    updateGuest(guest).then((response: GuestUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setGuest(response);
      }
    });
  }
  function getOneReservation(id: string) {
    return getOneReservations(id)
      .then((response: ReservationDetailsUiModel | ErrorInfo): any => {
        if (!isErrorInfo(response)) {
          if (response && response.paymentList && response.paymentList.length > 0) {
            response = {
              ...response,
              paymentList: response.paymentList
                .filter(Boolean)
                .sort((a, b) => b.paymentAmount - a.paymentAmount)
                .sort(
                  (a, b) =>
                    moment(a.dueDate).hours(0).seconds(0).milliseconds(0).unix() -
                    moment(b.dueDate).hours(0).seconds(0).milliseconds(0).unix()
                )
            };
          }
          setReservation(response);
          setReservationStatus(response.status);
          if (response.guestIds && response.guestIds.length) {
            getOneGuestObject(response.guestIds[0]);
          }

          return getUnitTypeDetails(response.unitTypeId, getModuleName());
        }
      })
      .then((responseUnit: UnitTypeUiModel | ErrorInfo) => {
        if (!isErrorInfo(responseUnit)) {
          setUnitType(responseUnit);
        }
      });
  }
  return (
    <div className={styles['reservation-edit-container']}>
      {reservation && unitType && (
        <>
          <div className={styles['reservation-edit-container__title']}>
            <div className={styles['reservation-edit-container__title__header']}>
              Reservations #{reservation.id}{' '}
            </div>
            <div className={styles['reservation-edit-container__title__select']}>
              <Select
                isDisabled={true}
                styles={reservationStatusColourStyles(reservationStatus)}
                options={dropdownItemStatus[reservation.status]}
                defaultValue={dropdownItemStatus[reservation.status][0]}
                onChange={(
                  value: ValueType<{ value: ReservationStatus; label: string }, false>
                ) => {
                  setShowAddCommentModal(true);
                  setReservationStatus(value ? value.value : '');
                }}
              />
              <p className={styles['reservation-edit-container__reservation-item--out']}>
                {reservation && reservation.source === ReservationSource.CHANNEL_MANAGER
                  ? `Status updates only allowed on ${
                      reservation.channel ? reservation.channel.toUpperCase() : 'OTA directly'
                    }`
                  : ''}
              </p>
            </div>
          </div>
          <div className={styles['reservation-edit-container__status-details']}>
            <div className={styles['reservation-edit-container__status-details__dates']}>
              <div>
                <div className={styles['reservation-edit-container__status-details__dates__label']}>
                  Check in date
                </div>
                <div className={styles['reservation-edit-container__status-details__dates__value']}>
                  {new Date(reservation.checkInDate).toDateString()}
                </div>
              </div>
              <ArrowForward color="inherit" fontSize="large" />
              <div>
                <div className={styles['reservation-edit-container__status-details__dates__label']}>
                  Check out date
                </div>
                <div className={styles['reservation-edit-container__status-details__dates__value']}>
                  {new Date(reservation.checkOutDate).toDateString()}
                </div>
              </div>
            </div>
            <div className={styles['reservation-edit-container__status-details__payments']}>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__label']}
                >
                  Payments Status
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__value']}
                  style={{
                    color: getPaymentStatusColor(getPaymentStatus(reservation.paymentList || []))
                      .color
                  }}
                >
                  {getPaymentStatus(reservation.paymentList || []) === PaymentStatus.COLLECTED
                    ? 'On Time'
                    : PaymentStatus.OVERDUE}
                </div>
              </div>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__label']}
                >
                  Payment Overview
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__value']}
                >
                  {reservation.paymentList && (
                    <NumberFormat
                      value={reservation.paymentList
                        .filter((payment) => payment.status === PaymentStatus.COLLECTED)
                        .reduce(
                          (prev, current) =>
                            current.direction === 'out'
                              ? prev - ((current && current.paymentAmount) || 0)
                              : prev + ((current && current.paymentAmount) || 0),
                          0
                        )}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        reservation.paymentList.length > 0 && reservation.paymentList[0].currency
                          ? CURRENCY[reservation.paymentList[0].currency] + ' '
                          : 'EGP '
                      }
                    />
                  )}
                  /
                  {reservation.paymentList && (
                    <NumberFormat
                      value={reservation.paymentList.reduce(
                        (prev, current) =>
                          current.direction === 'out'
                            ? prev - ((current && current.paymentAmount) || 0)
                            : prev + ((current && current.paymentAmount) || 0),
                        0
                      )}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        reservation.paymentList.length > 0 && reservation.paymentList[0].currency
                          ? CURRENCY[reservation.paymentList[0].currency] + ' '
                          : 'EGP '
                      }
                    />
                  )}
                </div>
              </div>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__label']}
                >
                  Late payments
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__value']}
                >
                  {getPaymentStatus(reservation.paymentList || []) === PaymentStatus.COLLECTED
                    ? '-'
                    : reservation.paymentList && (
                        <NumberFormat
                          value={reservation.paymentList
                            .filter((payment) => payment.status === PaymentStatus.OVERDUE)
                            .reduce(
                              (prev, current) => prev + ((current && current.paymentAmount) || 0),
                              0
                            )}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={
                            reservation.paymentList.length > 0 &&
                            reservation.paymentList[0].currency
                              ? CURRENCY[reservation.paymentList[0].currency] + ' '
                              : 'EGP '
                          }
                          style={{ color: '#FF0000' }}
                        />
                      )}
                </div>
              </div>
            </div>
            <div className={styles['reservation-edit-container__status-details__duration']}>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__duration__label']}
                >
                  Duration
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__duration__value']}
                >
                  {moment(Number(reservation.checkOutDate)).diff(
                    moment(Number(reservation.checkInDate)),
                    'days'
                  ) + 1}{' '}
                  Days
                </div>
              </div>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__duration__label']}
                >
                  Guests
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__duration__value']}
                >
                  {reservation.guestIds ? reservation.guestIds.length : 0}
                </div>
              </div>
            </div>
          </div>
          <div className={styles['reservation-edit-container__details-container']}>
            <div className={styles['reservation-edit-container__reservation-details']}>
              <div className={styles['reservation-edit-container__reservation-details__unit']}>
                <img
                  className={styles['reservation-edit-container__reservation-details__unit__image']}
                  src={`${unitType.coverImage}`}
                  alt="unit cover"
                />
                <div
                  className={
                    styles['reservation-edit-container__reservation-details__unit__area-container']
                  }
                >
                  <div
                    className={
                      styles[
                        'reservation-edit-container__reservation-details__unit__area-container__area'
                      ]
                    }
                  >
                    {reservation.area}
                  </div>
                </div>
                <div
                  className={
                    styles['reservation-edit-container__reservation-details__unit__details']
                  }
                >
                  <div>
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__unit__details__label'
                        ]
                      }
                    >
                      Unit type
                    </div>
                    <a
                      href={`${process.env.PUBLIC_URL}/unit-types/edit/${unitType.id}`}
                      className={`
                      ${styles['reservation-edit-container__reservation-details__unit__details__value']} ${styles['reservation-edit-container__reservation-details__unit__details__value__link']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {unitType.name}
                      {'  '} <ArrowForward color="inherit" fontSize="inherit" />
                    </a>
                  </div>
                  <div>
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__unit__details__label'
                        ]
                      }
                    >
                      Property
                    </div>
                    <a
                      href={`${process.env.PUBLIC_URL}/properties/edit/${reservation.propertyId}`}
                      className={`
                      ${styles['reservation-edit-container__reservation-details__unit__details__value']} ${styles['reservation-edit-container__reservation-details__unit__details__value__link']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {reservation.propertyName}
                      {'  '} <ArrowForward color="inherit" fontSize="inherit" />
                    </a>
                  </div>
                  <div>
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__unit__details__label'
                        ]
                      }
                    >
                      Room Name
                    </div>
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__unit__details__value'
                        ]
                      }
                    >
                      {unitType && unitType.units && unitType.units.length > 0 && reservation.roomId
                        ? unitType.units.find((unit) => unit.unitId === reservation.roomId)
                            ?.shortName
                        : 'UNASSIGNED'}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles['reservation-edit-container__reservation-details__contacts']}>
                <div
                  className={
                    styles['reservation-edit-container__reservation-details__contact-container']
                  }
                >
                  <div
                    className={
                      styles[
                        'reservation-edit-container__reservation-details__contact-container__header'
                      ]
                    }
                  >
                    Contact Info{' '}
                    <span
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__contact-container__sub-header'
                        ]
                      }
                    >
                      -from booking request
                    </span>
                  </div>

                  <div
                    className={
                      styles[
                        'reservation-edit-container__reservation-details__guest-container__guest-wrapper'
                      ]
                    }
                  >
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__guest-container__guest'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest__label'
                          ]
                        }
                      >
                        User #
                      </div>
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest__value'
                          ]
                        }
                      >
                        {reservation.userId || '--'}
                      </div>
                    </div>
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__guest-container__guest'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest__label'
                          ]
                        }
                      >
                        Contact Name
                      </div>
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest__value'
                          ]
                        }
                      >
                        {reservation.primaryGuestName}
                        {/* <EdiText
                          type="text"
                          value={contactInfo.guestInfo.name}
                          onSave={(val) => {
                            console.log('name changed');
                          }}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    styles['reservation-edit-container__reservation-details__guest-container']
                  }
                >
                  {!reservation.guestIds || reservation.guestIds.length === 0 ? (
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__guest-container__add-guest'
                        ]
                      }
                    >
                      <img src={`${PUBLIC_PATH}/assets/jpeg/add-guest.jpeg`} alt="add-user" />
                      <p>No Guests added yet</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {reservation.guestIds && reservation.guestIds.length ? (
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__guest-container__tabs-container'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__tabs-container__tabs'
                          ]
                        }
                      >
                        <Tabs
                          data={{
                            tabsList: reservation
                              ? reservation.guestIds &&
                                reservation.guestIds.map((guest, index) => ({
                                  component: <></>,
                                  key: `Guest ${index + 1}`,
                                  id: guest
                                }))
                              : [],
                            defaultTab: 'Guest 1'
                          }}
                          style={{ position: TabsPosition.LEFT }}
                          eventHandlers={{
                            tabChangeHandler: (tabKey, id) => {
                              if (id) {
                                getOneGuestObject(id);
                              }
                            }
                          }}
                        />
                      </div>
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__tabs-container__add'
                          ]
                        }
                        onClick={() => {
                          setShowAddGuestModal(true);
                        }}
                      >
                        <AddBoxIcon color="primary" fontSize="inherit" />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {guest && (
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__guest-container__guest-wrapper'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          First Name
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__value'
                            ]
                          }
                        >
                          <EdiText
                            type="text"
                            value={guest.firstName}
                            onSave={(val) => {
                              updateOneGuest({
                                ...guest,
                                firstName: val
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          Last Name
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__value'
                            ]
                          }
                        >
                          <EdiText
                            type="text"
                            value={guest.lastName}
                            onSave={(val) => {
                              updateOneGuest({ ...guest, lastName: val });
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className={`
                        ${styles['reservation-edit-container__reservation-details__guest-container__guest']} ${styles['reservation-edit-container__reservation-details__guest-container__guest--full-row']}`}
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          National ID:
                        </div>
                        {guest.document ? (
                          guest.document
                            .filter(
                              (doc) => !doc.type || doc.type === GuestDocumentType.NATIONAL_ID
                            )
                            .map((doc, index) => (
                              <a
                                href={doc.documentUrl}
                                // className={
                                //   styles[
                                //     'reservation-edit-container__reservation-details__guest-container__guest__link'
                                //   ]
                                // }
                              >
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__reservation-details__guest-container__guest__link-container'
                                    ]
                                  }
                                >
                                  {doc.documentUrl &&
                                  doc.documentUrl.split('.').length > -1 &&
                                  FILE_TYPES[
                                    `${doc.documentUrl.split('.').pop()?.toUpperCase()}`
                                  ] ? (
                                    <img
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                        ]
                                      }
                                      src={
                                        FILE_TYPES[doc.documentUrl.split('.').pop().toUpperCase()]
                                          .image
                                      }
                                    ></img>
                                  ) : (
                                    <img
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                        ]
                                      }
                                      src={`${PUBLIC_PATH}/assets/pngs/file-icon.png`}
                                    ></img>
                                  )}
                                  <div
                                    className={
                                      styles[
                                        'reservation-edit-container__reservation-details__guest-container__guest__link-container__details'
                                      ]
                                    }
                                  >
                                    <div
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__filename'
                                        ]
                                      }
                                    >
                                      {`National ID ${index + 1}`}
                                    </div>
                                    <div
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__file-id'
                                        ]
                                      }
                                    >
                                      {doc.documentId}
                                    </div>
                                  </div>
                                </div>
                              </a>
                            ))
                        ) : (
                          <></>
                        )}
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          Passport:
                        </div>
                        {guest.document ? (
                          guest.document
                            .filter((doc) => doc.type === GuestDocumentType.PASSPORT)
                            .map((doc, index) => (
                              <a
                                href={doc.documentUrl}
                                // className={
                                //   styles[
                                //     'reservation-edit-container__reservation-details__guest-container__guest__link'
                                //   ]
                                // }
                              >
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__reservation-details__guest-container__guest__link-container'
                                    ]
                                  }
                                >
                                  {doc.documentUrl &&
                                  doc.documentUrl.split('.').length > -1 &&
                                  FILE_TYPES[
                                    `${doc.documentUrl.split('.').pop()?.toUpperCase()}`
                                  ] ? (
                                    <img
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                        ]
                                      }
                                      src={
                                        FILE_TYPES[doc.documentUrl.split('.').pop().toUpperCase()]
                                          .image
                                      }
                                    ></img>
                                  ) : (
                                    <img
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                        ]
                                      }
                                      src={`${PUBLIC_PATH}/assets/pngs/file-icon.png`}
                                    ></img>
                                  )}
                                  <div
                                    className={
                                      styles[
                                        'reservation-edit-container__reservation-details__guest-container__guest__link-container__details'
                                      ]
                                    }
                                  >
                                    <div
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__filename'
                                        ]
                                      }
                                    >
                                      {`Passport Document ${index + 1}`}
                                    </div>
                                    <div
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__file-id'
                                        ]
                                      }
                                    >
                                      {doc.documentId}
                                    </div>
                                  </div>
                                </div>
                              </a>
                            ))
                        ) : (
                          <></>
                        )}
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          Marriage Certificate:
                        </div>
                        {guest.document ? (
                          guest.document
                            .filter((doc) => doc.type === GuestDocumentType.MARRIAGE_DOC)
                            .map((doc, index) => (
                              <a
                                href={doc.documentUrl}
                                // className={
                                //   styles[
                                //     'reservation-edit-container__reservation-details__guest-container__guest__link'
                                //   ]
                                // }
                              >
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__reservation-details__guest-container__guest__link-container'
                                    ]
                                  }
                                >
                                  {doc.documentUrl &&
                                  doc.documentUrl.split('.').length > -1 &&
                                  FILE_TYPES[
                                    `${doc.documentUrl.split('.').pop()?.toUpperCase()}`
                                  ] ? (
                                    <img
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                        ]
                                      }
                                      src={
                                        FILE_TYPES[doc.documentUrl.split('.').pop().toUpperCase()]
                                          .image
                                      }
                                    ></img>
                                  ) : (
                                    <img
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                        ]
                                      }
                                      src={`${PUBLIC_PATH}/assets/pngs/file-icon.png`}
                                    ></img>
                                  )}
                                  <div
                                    className={
                                      styles[
                                        'reservation-edit-container__reservation-details__guest-container__guest__link-container__details'
                                      ]
                                    }
                                  >
                                    <div
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__filename'
                                        ]
                                      }
                                    >
                                      {`Marriage Document ${index + 1}`}
                                    </div>
                                    <div
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__file-id'
                                        ]
                                      }
                                    >
                                      {doc.documentId}
                                    </div>
                                  </div>
                                </div>
                              </a>
                            ))
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles['reservation-edit-container__payments__header']}>
                <span>Payments</span>
              </div>
              {reservation && reservation.paymentList && reservation.paymentList.length ? (
                reservation.paymentList.map((payment, index) => (
                  <div className={styles['reservation-edit-container__payments__list']}>
                    <Accordion
                      key={index}
                      TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        classes={{
                          root:
                            payment.direction === 'out'
                              ? classes.refundRoot
                              : payment.paymentAmount <= 0
                              ? classes.disabledRoot
                              : classes.defaultRoot
                        }}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id={payment.id}
                      >
                        <div className={styles['reservation-edit-container__payments']}>
                          <div className={styles['reservation-edit-container__payments__item']}>
                            <div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__label']
                                }
                              >
                                Payment Id
                              </div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__value']
                                }
                              >
                                {payment.id}
                              </div>
                            </div>
                            <div>
                              <div
                                className={`${
                                  styles['reservation-edit-container__payments__item__label']
                                } ${
                                  payment && payment.paymentAmount <= 0
                                    ? styles['reservation-edit-container__reservation-item--out']
                                    : ''
                                }`}
                              >
                                Amount
                              </div>
                              <div
                                className={`${
                                  styles['reservation-edit-container__payments__item__value']
                                } ${
                                  payment && payment.paymentAmount <= 0
                                    ? styles['reservation-edit-container__reservation-item--out']
                                    : ''
                                }`}
                              >
                                <NumberFormat
                                  value={payment.paymentAmount || 0}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={
                                    payment && payment.currency
                                      ? CURRENCY[payment.currency] + ' '
                                      : 'EGP '
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__label']
                                }
                              >
                                Direction
                              </div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__value']
                                }
                              >
                                {`${payment.direction === 'out' ? 'PAY-OUT' : 'PAY-IN'}`}
                              </div>
                            </div>
                            <div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__label']
                                }
                              >
                                due date
                              </div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__value']
                                }
                              >
                                {new Date(payment.dueDate).toDateString()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          className={styles['reservation-edit-container__payments__item__details']}
                        >
                          <div
                            className={
                              styles['reservation-edit-container__payments__item__details__item']
                            }
                          >
                            <div
                              className={
                                styles[
                                  'reservation-edit-container__payments__item__details__item__title'
                                ]
                              }
                            >
                              Fee name
                            </div>
                            {/* <div
                      className={
                        styles['reservation-edit-container__payments__item__details__item__title']
                      }
                    >
                      Type
                    </div> */}
                            <div
                              className={
                                styles[
                                  'reservation-edit-container__payments__item__details__item__title'
                                ]
                              }
                            >
                              Amount
                            </div>
                          </div>
                          {payment &&
                            payment.paymentItems &&
                            payment.paymentItems.length > 0 &&
                            payment.paymentItems.map((paymentItem, index) => (
                              <div
                                className={
                                  styles[
                                    'reservation-edit-container__payments__item__details__item'
                                  ]
                                }
                              >
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__details__item__content'
                                    ]
                                  }
                                >
                                  {paymentItem.feeName === 'Unit Price' &&
                                  paymentItem.feeValue &&
                                  paymentItem.duration
                                    ? `${paymentItem.feeValue / paymentItem.duration}x${
                                        paymentItem.duration
                                      }`
                                    : paymentItem.feeName}
                                </div>
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__details__item__content'
                                    ]
                                  }
                                >
                                  <NumberFormat
                                    value={paymentItem.feeValue}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={
                                      paymentItem.currency
                                        ? CURRENCY[paymentItem.currency] + ' '
                                        : 'EGP '
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ReservationsEditContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken,
    permissions: state.auth.permissions
  };
}
function getPaymentStatus(paymentList: PaymentModel[]): PaymentStatus {
  const isOverDue = paymentList.find((payment) => payment.status === PaymentStatus.OVERDUE);
  return isOverDue ? PaymentStatus.OVERDUE : PaymentStatus.COLLECTED;
}
function getReservationStatusColor(value: ReservationStatus) {
  const colorObj = {
    backgroundColor: '',
    color: ''
  };
  if (value === ReservationStatus.CONFIRMATION_PENDING) {
    colorObj.backgroundColor = '#F5F7FC';
    colorObj.color = '#F38831';
  }
  if (value === ReservationStatus.CHECKED_IN) {
    colorObj.backgroundColor = '#c8ffe4';
    colorObj.color = '#008000';
  }
  if (value === ReservationStatus.IN_HOUSE) {
    colorObj.backgroundColor = '#c8ffe4';
    colorObj.color = '#008000';
  }
  if (value === ReservationStatus.CHECKED_OUT) {
    colorObj.backgroundColor = '#f5f7fc';
    colorObj.color = '#a6a6a6';
  }
  if (value === ReservationStatus.CHECKED_IN_STARTED) {
    colorObj.backgroundColor = '#ffe1c9';
    colorObj.color = '#f38831';
  }
  if (value === ReservationStatus.PENDING_CHECK_IN) {
    colorObj.backgroundColor = '#f5f7fc';
    colorObj.color = '#a6a6a6';
  }
  if (value === ReservationStatus.CANCELED) {
    colorObj.backgroundColor = '#ffacac';
    colorObj.color = '#ff0000';
  }
  if (value === ReservationStatus.EXPIRED) {
    colorObj.backgroundColor = '#e5e5e5';
    colorObj.color = '#ff0000';
  }
  return colorObj;
}
function getPaymentStatusColor(value: PaymentStatus) {
  const colorObj = {
    backgroundColor: '',
    color: ''
  };
  if (value === PaymentStatus.OVERDUE) {
    colorObj.backgroundColor = '#FFACAC';
    colorObj.color = '#E00707';
  }
  if (value === PaymentStatus.COLLECTED) {
    colorObj.backgroundColor = '#C8FFE4';
    colorObj.color = '#06A458';
  }
  if (value === PaymentStatus.PENDING) {
    colorObj.backgroundColor = '#F5F7FC';
    colorObj.color = '#000000';
  }
  if (value === PaymentStatus.CANCELED) {
    colorObj.backgroundColor = '#F5F7FC';
    colorObj.color = '#FF0000';
  }

  return colorObj;
}
const dropdownItemStatus: {
  [key: string]: {
    label: string;
    value: ReservationStatus;
    isDisabled?: boolean;
  }[];
} = {
  [ReservationStatus.CONFIRMATION_PENDING as string]: [
    {
      label: 'confirmation pending',
      value: ReservationStatus.CONFIRMATION_PENDING,
      isDisabled: true
    },
    { label: 'Check-in started', value: ReservationStatus.CHECKED_IN_STARTED },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.PENDING_CHECK_IN as string]: [
    {
      label: 'Pending Check-in',
      value: ReservationStatus.PENDING_CHECK_IN,
      isDisabled: true
    },
    { label: 'Check-in started', value: ReservationStatus.CHECKED_IN_STARTED },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.CHECKED_IN_STARTED as string]: [
    {
      label: 'Check-in started',
      value: ReservationStatus.CHECKED_IN_STARTED,
      isDisabled: true
    },
    { label: 'Check in', value: ReservationStatus.CHECKED_IN },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.CHECKED_IN as string]: [
    { label: 'Check in', value: ReservationStatus.CHECKED_IN, isDisabled: true },
    { label: 'Check out', value: ReservationStatus.CHECKED_OUT },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.IN_HOUSE as string]: [
    { label: 'In House', value: ReservationStatus.IN_HOUSE, isDisabled: true },
    { label: 'Check out', value: ReservationStatus.CHECKED_OUT },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.CHECKED_OUT as string]: [
    {
      label: 'Check out',
      value: ReservationStatus.CHECKED_OUT,
      isDisabled: true
    },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.CANCELED as string]: [
    {
      label: 'Canceled',
      value: ReservationStatus.CANCELED,
      isDisabled: true
    }
  ],
  [ReservationStatus.EXPIRED as string]: [
    {
      label: 'Expired',
      value: ReservationStatus.EXPIRED,
      isDisabled: true
    }
  ]
};
