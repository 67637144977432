import { AreaModel } from '../models/area.model';
import { AreaUiModel } from '../ui-models/area.ui-model';

function mapToUI(areaData: AreaModel): AreaUiModel {
  return {
    id: areaData.id || '',
    name: areaData.name,
    defaultLocation: areaData.defaultLocation,
    order: areaData.order,
    defaultImage: areaData.defaultImage,
    sponsoredImage: areaData.sponsoredImage
  };
}

function mapToModel(areaData: AreaUiModel): AreaModel {
  return {
    id: areaData.id,
    name: areaData.name,
    order: areaData.order,
    defaultImage: areaData.defaultImage,
    sponsoredImage: areaData.sponsoredImage
  };
}

function mapToUIList(areasList: AreaModel[]): AreaUiModel[] {
  return areasList.map(mapToUI);
}

export default {
  mapToUI,
  mapToModel,
  mapToUIList
};
