import { GuestModel } from '../models/guest.model';
import { GuestUiModel } from '../ui-models/guest.ui-model';

function mapToUI(guestData: GuestModel): GuestUiModel {
  return {
    id: guestData.id || '',
    firstName: guestData.firstName,
    lastName: guestData.lastName,
    email: guestData.email,
    phoneNumber: guestData.phoneNumber,
    dob: guestData.dob,
    gender: guestData.gender,
    address: guestData.address,
    document: guestData.document
  };
}

export default {
  mapToUI
};
