export interface GuestModel {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dob?: string;
  gender?: 'male' | 'female';
  address?: string;
  document?: {
    documentId: string;
    type: GuestDocumentType;
    documentNumber: string;
    documentIssueDate: number;
    expirationDate: number;
    issueCountry: string;
    documentUrl?: string;
  }[];
}

export enum GuestDocumentType {
  NATIONAL_ID = 'national_id',
  PASSPORT = 'passport',
  MARRIAGE_DOC = 'marriage_doc'
}
